import React, { useContext, Dispatch, SetStateAction, MouseEvent, useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useParams, useHistory, useRouteMatch} from "react-router-dom";
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";

// Interfaces
import { ModuleDashboard, Progression, Student } from "../../../../interfaces/Dashboard";

// Utils
import { colors, tagFilter } from "../utils";
import { getModuleById, getObjectiveById } from "../../../../utils/dataRetrieval";
import { ProgressionData } from "../../StudentProgression/StudentProgression";

// reach ui
import Tooltip, { useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";

// Interfaces
interface ProgressProps {
  modules?: ModuleDashboard[];
  setProgression: Dispatch<SetStateAction<ProgressionData | undefined>>
  progressFilter: string | undefined;
}

interface ObjectiveTagProps {
  status: "completed" | "none" | "inProgress";
}

interface ObjectiveContainerProps {
  status: "completed" | "none" | "inProgress";
  alert: "positive" | "negative" | "none";
  progressFilter: string | undefined;
}

interface ObjectiveAlertProps {
  alert: "positive" | "negative" | "none";
}

interface ParamTypes {
  classroomId: string;
  studentId: string;
}

interface CurrentPaginationTypes {
  translationValue: string | null;
  moduleId: string | null;
}

interface TagContainerProps {
  moduleId: string | null;
}

export default function Progress({ progressFilter, modules, setProgression }: ProgressProps) {
  const toolTipStyle = {
    backgroundColor: "#444",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "8px"
  }
  const { classroomId, studentId } = useParams<ParamTypes>();
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);
  const history = useHistory();
  let { url } = useRouteMatch();

  const TagContainerRef = useRef<HTMLDivElement>(null);
  const TagRef = useRef<HTMLDivElement>(null);


  const handleProgressArrow = (e:MouseEvent<HTMLButtonElement>, modifier: string, moduleId: string, objectivesLength: number) => {
    
    let el = e.currentTarget.closest("div")?.children[3].children[0] as HTMLDivElement
    let currentX = Number(window.getComputedStyle(el).transform.split(',')[4]);

    if(modifier === "-"){      
      if (isNaN(currentX) && (objectivesLength > 7)){        
        el.style.transform = "translateX(-115px)";
      }else if (!isNaN(currentX) && currentX === (-115 * (objectivesLength - 7))) {        
        return
      }else if(!isNaN(currentX)) {        
        el.style.transform = "translateX(" + (currentX - 115)+"px)"
      }
    }else if(modifier === "+") {
      if(isNaN(currentX)){
        return
      } else if (!isNaN(currentX) && currentX === 0) {
        return
      } else if (!isNaN(currentX)) {
        el.style.transform = "translateX(" + (currentX + 115) + "px)"
      }
    }
  }

  return (
    <ProgressContainer>
      {modules?.map((module, i) => (
      <FieldContainer key={`module-${i}`}>
        <ModuleColor color={'#888'}></ModuleColor>
        <TitleContainer onClick={()=> history.push(`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${module.id}`)}>
          <FieldTitle>{getModuleById(module.id, data).title.short}. {getModuleById(module.id, data).title.long}</FieldTitle>
          <ProgressBarContainer>
            <ProgressBar percentage={ Number(((module.students[studentId].moduleProgression)*100).toFixed(1))}>
              <ProgressBarPercentage>{`${ ((module.students[studentId].moduleProgression)*100).toFixed(1) || 0}%`}</ProgressBarPercentage>
            </ProgressBar>
            <ClassAverage average={ Number(((module.progression)*100).toFixed(1)) || 0}></ClassAverage>
            <ClassAverageInfo>{config.i18n.dashboard?.studentDetails.classAverage} { Number(((module.progression)*100).toFixed(1)) || 0}%</ClassAverageInfo>
          </ProgressBarContainer>
        </TitleContainer>

        <TagArrow onClick={(e) => handleProgressArrow(e, '+', module.id, module.students[studentId]?.objectives.length)}>
          <span className="material-icons">arrow_back</span>
        </TagArrow>
        <TagOverflowContainer className="test">
          <TagContainer ref={TagContainerRef} moduleId={module.id} style={{width: module.students[studentId]?.objectives.length * 119}}>
            {module.students[studentId]?.objectives.map(({ status, alert, id, progression, timeSpent }, i) => {
              return (
                <Tooltip style={toolTipStyle} label={`${config.i18n.dashboard?.common.objectiveShort} ${getObjectiveById(id, data).title.short}${config.i18n.misc.colon}${getObjectiveById(id, data).title.long}`}>
                <ObjectiveContainer ref={TagRef} key={`objective-${i}`} progressFilter={progressFilter} alert={alert} status={status} onClick= {
                  () => 
                    status === "completed" || status === "inProgress" ? setProgression({ classId: classroomId, objectiveId: id, student: module.students[studentId], progression: progression, timeSpent: timeSpent, objectiveStatus: status}) : null
                }>
                    <ObjectiveTitle>{config.i18n.dashboard?.common.objectiveShort} {getObjectiveById(id, data).title.short}</ObjectiveTitle>
                    <ObjectiveTag status={status}>
                      <ObjectiveAlert alert={alert}></ObjectiveAlert>
                    </ObjectiveTag>
                </ObjectiveContainer>
                    </Tooltip>
              )
            })}
          </TagContainer>
        </TagOverflowContainer>
        <TagArrow onClick={(e) => handleProgressArrow(e, '-', module.id, module.students[studentId]?.objectives.length)}>
          <span className="material-icons">arrow_forward</span>
        </TagArrow>

      </FieldContainer>
        ))}
    </ProgressContainer>
  )
}

const ProgressContainer = styled.div`
  background: ${colors.lightGrey};
  padding: 16px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  position: relative;
`

const ModuleColor = styled.div<{ color: string }>`
  width: 16px;
  height:calc(100% - 48px);
  position: absolute;
  left:-24px;
  top: 50%;
  transform:translateY(-50%);
  background-color: ${props => props.color};
  border-radius: 5px 0 0 5px;
`

//
const TitleContainer = styled.div`
  width: 200px;
  margin: 0px 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
`

const TagArrow =  styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin: 0 8px;
  padding: 16px;
  background: ${colors.mediumGrey};
  color: ${colors.lightGrey};
  border-radius: 8px;
`

const TagOverflowContainer = styled.div`
  overflow: hidden;
  width:100%;
  position: relative;
`

const TagContainer = styled.div<TagContainerProps>`
  display: flex;
  width: 100%;
`

const FieldTitle = styled.h5`
  font-size: 1.2em;
  padding: 0 4px;
`

const ProgressBarContainer = styled.div`
  border: 2px dashed ${colors.blueTag};
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  width: 70%;
  height: 20px;
  margin: 8px 0;
  position: relative;
`
const ClassAverage = styled.div<{ average: number; }>`
  position:absolute;
  left: ${props => props.average + '%'};
  top: 0;
  height: 30px;
  border: 1px dashed #555;
`

const ClassAverageInfo = styled.div`
  padding: 16px 0 0 0;
  font-size: 0.8em;
`

//
const ProgressBar = styled.div<{ percentage: number }>`
  height: 100%;
  width: ${props => (props.percentage || 0) + '%'};
  border-radius: 8px;
  background: ${colors.blueTag};
  position: relative;
`

const ProgressBarPercentage = styled.div`
  font-size: 0.8em;
  position: absolute;
  left: 8px;
  top:50%;
  transform:translateY(-50%);
`

//
const ObjectiveContainer = styled.div<ObjectiveContainerProps>`
  width: 115px;
  display: ${props => tagFilter(props.progressFilter, props.status, props.alert) ? "none" : "flex"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.status === "completed" || props.status === "inProgress" ? 'pointer' : 'default'};
`

const ObjectiveTitle = styled.h5`
  width:90%;
  text-align: center;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ObjectiveTag = styled.div<ObjectiveTagProps>`
  background: ${props => props.status === "completed" ? colors.blueTag : props.status === "inProgress" ? colors.lightBlue : "#fff"};
  border: 2px dashed ${colors.blueTag};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  width: 90%;
  height: 46px;
  border-radius: 8px;
`

const ObjectiveAlert = styled.div<ObjectiveAlertProps>`
    display: ${(props) => (props.alert === "none" ? "none" : "block")};
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 50%;
    background: ${(props) => (props.alert === "positive" ? "green" : "#FF6138")};
`;