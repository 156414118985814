import { ActivityShell } from "@evidenceb/gameplay-interfaces";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * Developer component that allows you to play a single exercise by id from a
 * console command.
 */
const PlaySingleExercise: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        (window as any).playExercise = (
            id: string,
            shell: ActivityShell = ActivityShell.Wizard
        ) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: `Play single exercise ${id}`,
                level: Sentry.Severity.Info,
            });
            history.push(`/play/${shell}/${id}`);
        };
    });

    return <></>;
};

export default PlaySingleExercise;
