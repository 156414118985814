import { MicroServices } from "../interfaces/Config";

/**
 * Returns the set of endpoints that matches the first item in the msConfig file
 */
export const msConfigResolver = (
    config: MicroServices[],
    origin: string
): MicroServices | undefined => {
    return config.find((endpointSets) => origin.includes(endpointSets.match));
};
