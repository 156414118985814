import React, { useContext } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { ModuleDashboard } from "../../../../interfaces/Dashboard";
import { getModuleById } from "../../../../utils/dataRetrieval";
import ProgressBarLink from "../../ClassList/ProgressBarLink/ProgressBarLink";
import BarLink from "./BarLink/BarLink";
import ModuleDeactivation from "./ModuleDeactivation/ModuleDeactivation";
import ModuleHandler from "../PathStart/ModuleHandler";
import { ModulePathState, Student } from "../PathStart/useStartPath";

// [TEMP] While athena-am is a different instance than athena (this fakes the presence of a feature flag)
const FEATURE_MODULE_ACTIVATION = true;
const FEATURE_ADMATH_START_PATH = true;

/**
 * Component that displays a card with the state of the module. Possible
 * features include:
 * - Module activation/deactivation (ADMATH ONLY) (requires props activated and
 *   toggleActivation)
 * - Start path (ADMATH ONLY) (requires props modulePathState and
 *   setStudentsReadyForPath)
 */
const ModuleCard: React.FC<{
    classroomId: string;
    module: ModuleDashboard;
    nbClusters: number | undefined;
    index: number;
    activated?: boolean;
    toggleActivation?: () => void;
    modulePathState?: ModulePathState;
    setStudentsReadyForPath?: React.Dispatch<React.SetStateAction<Student[]>>;
}> = ({
    classroomId,
    module,
    index,
    nbClusters,
    activated,
    toggleActivation,
    modulePathState,
    setStudentsReadyForPath,
}) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    return (
        <div className="module-card" key={module.id}>
            <div className="card-title">{`${ getModuleById(module.id, data).title.short }. ${ getModuleById(module.id, data).title.long }`}</div>
            <div className="card-content">
                <div className="content">
                    {(!FEATURE_MODULE_ACTIVATION || activated) && (
                        <>
                            {FEATURE_ADMATH_START_PATH && modulePathState && (
                                <ModuleHandler
                                    {...modulePathState}
                                    setStudentsReadyForStart={
                                        setStudentsReadyForPath!
                                    }
                                />
                            )}

                            <ProgressBarLink
                                url={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${module.id}`}
                                progression={Math.round(module.progression * 100)}
                                title={`${config.i18n.dashboard?.common.progression}`}
                            />

                            {config.features.clustering && typeof nbClusters !== "undefined" && (
                                <BarLink
                                    title={`${nbClusters} ${nbClusters === 1 ? config.i18n.dashboard?.classDetails.identifiedGroup : config.i18n.dashboard?.classDetails.identifiedGroups}`}
                                    url={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}/${module.id}/${config.i18n.dashboard?.paths.groups}`}
                                    id={index}
                                />
                            )}

                            <div className="progression">
                                <div className="participants">
                                    {module.participants}{" "}
                                    {`${module.participants === 1 ? config.i18n.dashboard?.classDetails.savedProgression : config.i18n.dashboard?.classDetails.savedProgressions}`}
                                </div>

                                <div className="progression-state">
                                    <div className="progression-difficulties">{`${module.alert.hasDifficulties} ${config.i18n.dashboard?.classDetails.progressionRegression}`}</div>
                                    <div className="progression-improved">{`${module.alert.hasImproved} ${config.i18n.dashboard?.classDetails.progressionAcceleration}`}</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {FEATURE_MODULE_ACTIVATION && (
                    <ModuleDeactivation
                        active={activated!}
                        toggleStatus={toggleActivation!}
                        moduleId={module.id}
                        classroomId={classroomId}
                    />
                )}
            </div>
        </div>
    );
};

export default ModuleCard;
