import React, { useContext, useRef, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import { Classroom } from "../../../../interfaces/Dashboard";
import * as localStorage from "../../../../utils/localStorage";
import { fetchData, getData } from "../../../../utils/request-data";

const ClassName: React.FC<{
    classroom: Classroom;
}> = ({ classroom }) => {
    const { config } = useContext(configStore);
    const { setDashboard } = useContext(dashboardStore);
    const { session } = useContext(sessionStore);

    const [className, setClassName] = useState<string | undefined>();
    const [editClassNameStatus, setEditClassNameStatus] = useState<
        "idle" | "edit" | "saving"
    >("idle");

    const classNameInputRef = useRef<HTMLInputElement>(null);

    return (
        <h1>
            {editClassNameStatus === "idle" && (
                <div className="idle">
                    <span className="name">
                        {config.i18n.dashboard?.common.class} {classroom.name}
                    </span>
                    {config.features.classManagement && (
                        <button
                            onClick={() => {
                                setEditClassNameStatus("edit");
                                setClassName(classroom.name);
                                classNameInputRef.current?.focus();
                            }}
                        >
                            <span className="material-icons icon">
                                {" "}
                                drive_file_rename_outline{" "}
                            </span>
                        </button>
                    )}
                </div>
            )}

            {editClassNameStatus === "edit" && (
                <div className="edit">
                    <input
                        ref={classNameInputRef}
                        type="text"
                        value={className}
                        onChange={(e) => {
                            setClassName(e.currentTarget.value);
                        }}
                    />
                    <button
                        onClick={async () => {
                            let headers = new Headers({
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem<string>(
                                        localStorage.Key.TOKEN
                                    ),
                                "Content-Type": "application/json",
                            });
                            await fetchData<Classroom>(
                                config.msConfigs?.endpoints.users +
                                    "/api/classrooms/" +
                                    classroom.id +
                                    "/",
                                "PATCH",
                                { name: className },
                                headers
                            );
                            
                            // Reset dashboard
                            headers = new Headers({
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem<string>(
                                        localStorage.Key.TOKEN
                                    ),
                                "x-evb-origin": window.location.origin,
                                version: localStorage.getItem<string>(
                                    localStorage.Key.VERSION
                                )!,
                            });
                            const dashboard = await getData<any>(
                                config.msConfigs?.endpoints.analytics +
                                    "/teacher/" +
                                    session.userId +
                                    "/classrooms_analytics/",
                                headers
                            );
                            setDashboard(dashboard);

                            setEditClassNameStatus("saving");
                        }}
                    >
                        <span className="material-icons icon"> check </span>
                    </button>
                    <button
                        onClick={() => {
                            setClassName(classroom.name);
                            setEditClassNameStatus("idle");
                        }}
                    >
                        <span className="material-icons icon"> close </span>
                    </button>
                </div>
            )}

            {editClassNameStatus === "saving" && (
                <div className="saving">
                    <Loader />
                </div>
            )}
        </h1>
    );
};

export default ClassName;
