import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { configStore } from "../../../contexts/ConfigContext";
import { ModuleCluster, ParamTypes } from "../../../interfaces/Dashboard";
import * as localStorage from "../../../utils/localStorage";
import QuickStartCode from "./QuickStartCode/QuickStartCode";
import PathStartModal from "./PathStart/PathStartModal";
import useStartPath from "./PathStart/useStartPath";
import ClassName from "./ClassName/ClassName";
import StudentsInDifficulty from "./StudentsInDifficulty/StudentsInDifficulty";
import ModuleCard from "./ModuleCard/ModuleCard";
import { dashboardStore } from "../../../contexts/DashboardContext";

import "./ClassDetails.scss";
import "@reach/tooltip/styles.css";
import Loader from "../../../components/Loader/Loader";

// [TEMP] While athena-am is a different instance than athena (this fakes the presence of a feature flag)
const FEATURE_ADMATH_START_PATH = true;
const FEATURE_MODULE_ACTIVATION = true;
const FEATURE_QUICK_START = true;

/**
 * Page showing the details of all modules for a class. Possible features
 * include:
 * - Class code (via the classManagement flag)
 * - Clustering (via the clustering flag)
 * - Module activation/deactivation (ADMATH ONLY)
 * - Path start (ADMATH ONLY)
 * - Quick start (ADMATH ONLY)
 */
const ClassDetails: React.FC = () => {
    const { classroomId } = useParams<ParamTypes>();
    const { config } = useContext(configStore);
    const {
        dashboard: { classrooms, clustering },
    } = useContext(dashboardStore);

    const classroom = classrooms.find(
        (classroom) => classroom.id === classroomId
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);

    if (!classroom)
        // [TODO] Error handling
        throw new Error(`Classroom ${classroomId} not found`);

    const startPath = useStartPath(classroom);

    /**
     * Activation status (activated -> true / deactivated -> false) of the
     * modules
     */
    const [activatedModules, setActivatedModules] = useState<{
        [moduleId: string]: boolean;
    }>({});
    // Retrieve initial activation state of the modules
    useEffect(() => {
        if (!FEATURE_MODULE_ACTIVATION || !classroom) return;

        const headers = new Headers({
            version: localStorage.getItem<string>(localStorage.Key.VERSION)!,
            authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )!}`,
        });
        fetch(
            `${config.msConfigs!.endpoints.misc!}/get_module_status/${
                classroom.id
            }`,
            { headers }
        )
            .then(
                (response) =>
                    response.json() as Promise<{
                        data: { [moduleId: string]: { locked: boolean } };
                    }>
            )
            .then((json) => {
                setActivatedModules(
                    Object.keys(json.data).reduce(
                        (activatedModules, moduleId) => {
                            return {
                                ...activatedModules,
                                [moduleId]: !json.data[moduleId].locked,
                            };
                        },
                        {} as { [moduleId: string]: boolean }
                    )
                );
            })
            .catch(() => {
                // [TODO] Error Handling
            });
    }, [classroom]);

    useEffect(() => {
        if (startPath.modulesState) {
            setIsLoading(false);
        }
    }, [startPath.modulesState]);

    return (
        <div className="classrooms-details-container">
            { 
                startPath.studentsReadyForStart.length > 0 ? (
                    <PathStartModal
                        students={startPath.studentsReadyForStart}
                        onStartPath={startPath.startPath}
                        onModalClose={() => startPath.setStudentsReadyForStart([])}
                    />
                )
                : null
            }

            <header>
                <div className="breadcrumbs">
                    <ul>
                        <li>
                            <Link
                                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`}
                            >
                                {config.i18n.dashboard?.common.classes}
                            </Link>
                        </li>
                        <li>
                            {config.i18n.dashboard?.common.class}{" "}
                            {classroom?.name}
                        </li>
                    </ul>
                </div>
            </header>

            <div className="classroom-title">
                <ClassName classroom={classroom} />

                {config.features.classManagement && (
                    <div className="classCode">
                        <span className="code">
                            {config.i18n.dashboard?.classDetails.code}{" "}
                            {classroom?.external_id}
                        </span>
                        <div className="tool-tip">
                            <span className="text">
                                {
                                    config.i18n.dashboard?.classDetails
                                        .codeMessage
                                }
                            </span>
                            <span className="material-icons">help_outline</span>
                        </div>
                    </div>
                )}
            </div>

            {FEATURE_QUICK_START && (
                <QuickStartCode classroomId={classroomId} />
            )}
            {!isLoading ? (
                <div className="modules-container">
                    <div className="classroom-details-cards-container">
                        {classroom.modulesList.length &&
                        (!FEATURE_ADMATH_START_PATH ||
                            startPath.modulesState) ? (
                            classroom.modulesList.map((module, index) => (
                                <ModuleCard
                                    key={index}
                                    classroomId={classroomId}
                                    module={module}
                                    index={index}
                                    nbClusters={
                                        config.features.clustering &&
                                        clustering &&
                                        clustering[classroomId] &&
                                        clustering[classroomId][module.id] &&
                                        typeof clustering![classroomId][
                                            module.id
                                        ].error === "undefined"
                                            ? (
                                                  clustering![classroomId][
                                                      module.id
                                                  ] as ModuleCluster
                                              ).infosClustering.nbClusters
                                            : undefined
                                    }
                                    activated={activatedModules[module.id]}
                                    toggleActivation={() => {
                                        setActivatedModules((curr) => {
                                            return {
                                                ...curr,
                                                [module.id]: !curr[module.id],
                                            };
                                        });
                                    }}
                                    modulePathState={
                                        startPath.modulesState
                                            ? startPath.modulesState[module.id]
                                            : undefined
                                    }
                                    setStudentsReadyForPath={
                                        startPath.setStudentsReadyForStart
                                    }
                                />
                            ))
                        ) : (
                            <div className="no-data">
                                {config.i18n.dashboard?.common.noStudentsData}
                            </div>
                        )}
                    </div>

                    <StudentsInDifficulty classroom={classroom} />
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default ClassDetails;
