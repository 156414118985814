import React, { useEffect, useState, useContext, FormEvent } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  Classroom,
  ModuleDashboard,
  Progression,
  Student,
  ParamTypes,
} from "../../../interfaces/Dashboard";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import "./ProgressionOverview.scss";
import Loader from "../../../components/Loader/Loader";
import StudentProgression, { ProgressionData } from "../StudentProgression/StudentProgression";
import SimpleBar from 'simplebar-react';
import Tooltip, { useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import { getModuleById, getObjectiveById } from "../../../utils/dataRetrieval";
import { dashboardStore } from "../../../contexts/DashboardContext";

const ProgressionOverview: React.FC<{ classrooms: Classroom[] }> = ({ classrooms }) => {
  const history = useHistory();
  const { moduleId, classroomId } = useParams<ParamTypes>();

  const [currentClassroom, setCurrentClassroom] = useState<Classroom | undefined | null>();
  const [currentModule, setCurrentModule] = useState<ModuleDashboard>();
  const [error, setError] = useState<string>();
  const [progression, setProgression] = useState<ProgressionData | undefined>()
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);
  const { dashboard } = useContext(dashboardStore);

  const toolTipStyle = {
    backgroundColor: "#444",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "8px"
  }

  const setData = (classroomId: string, module_id?: string) => {
    let classroom = classrooms.find(
      (classroom, i) => classroom.id === classroomId
    ) as Classroom;
    let module;
    /*   console.log("classroomId", classroomId); */
    if (classroom) {
      if (error) {
        setError(undefined);
      }
      setCurrentClassroom(classroom);
      if (module_id) {
        module = classroom.modulesList.find(
          (module) => module.id === module_id
        );
        setCurrentModule(module);
        if (!module) {
          setCurrentModule(undefined);
          setError("Pas de données disponible pour cette classe");
        }
      } else {
        if (!classroom.modulesList[0]) {
          setCurrentModule(undefined);
          setError("Ce module n'existe pas");
        } else {
          setCurrentModule(classroom.modulesList[0]);
        }
      }
      history.push(
        `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroom.id}/${module_id ? module?.id : "0"
        }`
      );
    } else {
      history.push(`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`);
    }
  };

  const classRoomSelectHandler = (e: FormEvent<HTMLSelectElement>) => {
    setData(e.currentTarget.value);
  };

  const ModuleSelectHandler = (e: FormEvent<HTMLSelectElement>) => {
    setData(currentClassroom!.id, e.currentTarget.value);
  };

  const viewGroups = () => {
    history.push(`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}/${moduleId}/${config.i18n.dashboard?.paths.groups}`)
  };

  const sortByName = (a: string, b: string) => {
    return (currentModule!.students[a].lastname.localeCompare(currentModule!.students[b].lastname))
  }

  useEffect(() => {
    if (currentClassroom === undefined) {
      setData(classroomId, moduleId);
    }
    console.log("currentClassroom", currentClassroom);

  }, [currentClassroom]);

  if (!currentModule) return <Loader />;

  return (
    <div className="classroom-modules-overview">
      <header>

        <div className="breadcrumbs">
          <ul>
            <li>
              <Link
                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`}
              >
                {config.i18n.dashboard?.common.classes}
              </Link>
            </li>
            {currentClassroom ? (
              <li>
                <Link
                  to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${currentClassroom?.id}`}
                >
                  {config.i18n.dashboard?.common.class} {currentClassroom.name}
                </Link>
              </li>
            ) : null}
            <li>{config.i18n.dashboard?.common.progression}</li>
          </ul>
        </div>

        <div className={"classroom-navigation"}>
          <h1>{config.i18n.dashboard?.common.progression}</h1>
          <select
            onChange={classRoomSelectHandler}
            value={currentClassroom ? currentClassroom?.id : "0"}
            className="select"
          //style={{ width: "250px" }}
          >
            {classrooms.map((classroom, i) => (

              classroom.studentsNumber > 0 ?

                <option
                  key={"class-" + i}
                  value={classroom.id}
                >{`${config.i18n.dashboard?.common.class} ${classroom.name}`}</option> : null
            ))}
          </select>
          {currentClassroom ? (
            <select
              onChange={ModuleSelectHandler}
              value={currentModule?.id}
              className="select"
            >
              {currentClassroom?.modulesList.map((module, i) => (
                <option key={"module-" + i} value={module.id}>
                  {getModuleById(module.id, data).title.short}. {getModuleById(module.id, data).title.long}
                </option>
              ))}
            </select>
          ) : null}

          {
            config.features.clustering ? dashboard.clustering && dashboard.clustering[classroomId] && dashboard.clustering[classroomId][moduleId] &&
              typeof dashboard.clustering[classroomId][moduleId].error === "undefined" ? <button className="view-groups" onClick={viewGroups}>
                <span>{config.i18n.dashboard?.progressionOverview.seeGroupsOfSelection}</span>
                <span className="material-icons icon" translate="no">arrow_forward</span>
              </button>
              : <button className="view-groups view-groups--failed-clustering">
                <span>{config.i18n.dashboard?.progressionOverview.noClusteringGenerated}</span>
                <span className="material-icons icon" translate="no">highlight_off</span>
              </button>
              : null
          }
        </div>

      </header>

      {/**
       * Table BODY
       */}

      <div className="manuel">
        <div className="box validated-box"></div>
        <div>{`${config.i18n.dashboard?.progressionOverview.legend.validObjective}`}</div>
        <div className="box in-progress-box"></div>
        <div>{`${config.i18n.dashboard?.progressionOverview.legend.currentObjective}`}</div>
        <div className="box objective-not-started-box"></div>
        <div>{`${config.i18n.dashboard?.progressionOverview.legend.objectiveNotStarted}`}</div>
        <div className="circle has-improved" />
        <div>{`${config.i18n.dashboard?.progressionOverview.legend.progressionAcceleration}`}</div>
        <div className="circle has-difficulties" />
        <div>{`${config.i18n.dashboard?.progressionOverview.legend.progressionRegression}`}</div>
      </div>

      {!error ? (
        <div className="overview">
          <div className="table">

            <div className="column">

              <div className="cell">
                <span>{`${config.i18n.dashboard?.common.students}`}</span>
                <span>{`${config.i18n.dashboard?.common.objectives}`}</span>
                <span className="split"></span>
              </div>

              {Object.keys(currentModule!.students).sort(sortByName).map((studentId, i) => (

                <div key={`key-${i}`} className={`cell ${currentModule!.students[studentId].studentInDifficulty ? 'inDifficulty' : ''}`}>
                  <Link to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${currentClassroom?.id}/${studentId}`}>
                    <span className="name-container">
                      <span className="material-icons">person</span>
                      <span className="name">
                        {currentModule!.students[studentId].firstname} {currentModule!.students[studentId].lastname}
                        <span>{config.i18n.dashboard?.common.seeProfile} <span className="material-icons">chevron_right</span></span>
                      </span>
                    </span>
                  </Link>
                </div>

              ))}
            </div>

            <div className="rows-container">
              <SimpleBar style={{ width: "100%" }} autoHide={false}>
                <div className="container" id="rows-container" >

                  <div className="row th">
                    {currentModule?.objectives.map((objectiveId, i) => (
                      <div
                        className="cell"
                        key={"objective-" + i}
                        id={
                          i === 0
                            ? "start"
                            : i === currentModule?.objectives.length - 1
                              ? "end"
                              : ""
                        }
                      >
                        <Tooltip style={toolTipStyle} label={`${config.i18n.dashboard?.common.objectiveShort} ${getObjectiveById(objectiveId, data).title.short}${config.i18n.misc.colon}${getObjectiveById(objectiveId, data).title.long}`}>
                          <button>
                            <span>{config.i18n.dashboard?.common.objectiveShort} {getObjectiveById(objectiveId, data).title.short}</span>
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>

                  {Object.keys(currentModule!.students).sort(sortByName).map((studentId, i) => (
                    <div className="row tr" key={"row" + i}>
                      {currentModule!.students[studentId].objectives.map((objective, i) => (
                        <div
                          className={`cell ${objective.status}`}
                          key={"cell" + i}>
                          {
                            objective.status === "completed" || objective.status === "inProgress" ?
                              <Tooltip style={toolTipStyle} label={`${config.i18n.dashboard?.common.objectiveShort} ${getObjectiveById(objective.id, data).title.short}${config.i18n.misc.colon}${getObjectiveById(objective.id, data).title.long}`}>
                                <button onClick={
                                  () => setProgression({
                                    classId: currentClassroom?.id,
                                    objectiveId: objective.id,
                                    objectiveStatus: objective.status as "completed" | "inProgress",
                                    student: currentModule!.students[studentId],
                                    progression: objective.progression,
                                    timeSpent: objective.timeSpent
                                  })
                                }>
                                  <span className={`alert --${objective.alert}`}></span>
                                  <div className=""></div>
                                </button>
                              </Tooltip>
                              :
                              <Tooltip style={toolTipStyle} label={`${config.i18n.dashboard?.common.objectiveShort} ${getObjectiveById(objective.id, data).title.short}${config.i18n.misc.colon}${getObjectiveById(objective.id, data).title.long}`}>
                                <button>
                                  <span></span>
                                </button>
                              </Tooltip>
                          }
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      ) : (
        <p>{error}</p>
      )}

      {
        progression ?

          <StudentProgression
            data={progression} setProgression={setProgression} />

          : null
      }

    </div>
  );
};

export default ProgressionOverview;
