export const colors = {
    primaryStudent: "#2C3E50",
    lightGrey: "#ebe8e8",
    mediumGrey: "#C4C4C4",
    darkGrey: "#797979",
    lightBlue: "#CBE1EA",
    blueTag: "#8AC0EC",
    redIcon: "#FF6138",
    modules: [
        "#2C3E50",
        "#C3C703",
        "#BAE7E9",
        "#2D9CDB",
        "#9C73FF",
        "#B512DE",
        "#D61B5E",
    ],
};


export const tagFilter = (filter: string | undefined, status: string, alert: string | undefined): boolean => {
  let isFiltered = false;
  switch(filter) {
    case "difficulties":
      isFiltered = "negative" !== alert
      break;

    case "facilities":
      isFiltered = "positive" !== alert
      break;

    case "validatedObjectives":
      isFiltered = "completed" !== status
      break;

    case "currentObjective":      
      isFiltered = "inProgress" !== status
      break;
  }
  return isFiltered;
}