import React, { useEffect } from "react";
import { ConfigProvider } from "./contexts/ConfigContext";
import { HomeProvider } from "./contexts/HomeContext";
import { ContentPagesProvider } from "./contexts/ContentPagesContext";
import { DataProvider } from "./contexts/DataContext";
import Root from "./pages/Root/Root";
import "./scss/App.scss";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { DashboardProvider } from "./contexts/DashboardContext";
import { MathJaxProvider } from './contexts/MathJaxContext'
import { SessionProvider } from "./contexts/SessionContext";

const App = () => {
    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();
    useEffect(() => {
        trackPageView({});
    }, [trackPageView]);

    return (
        <DataProvider>
            <ConfigProvider>
                <DashboardProvider>
                    <HomeProvider>
                        <ContentPagesProvider>
                            <SessionProvider>
                                <MathJaxProvider>
                                    <Root />
                                </MathJaxProvider>
                            </SessionProvider>
                        </ContentPagesProvider>
                    </HomeProvider>
                </DashboardProvider>
            </ConfigProvider>
        </DataProvider>
    );
};

export default App;
