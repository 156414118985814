import { useContext, useEffect, useState } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import { Classroom } from "../../../../interfaces/Dashboard";
import * as localStorage from "../../../../utils/localStorage";

export interface ResponseStudent {
    id: string;
    isClusterAvailable: boolean;
    moduleStarted: boolean;
}

export interface Student extends ResponseStudent {
    moduleId: string;
    firstname: string;
    lastname: string;
}

/**
 * Status of the start path option
 */
export interface ModulePathState {
    /**
     * Status of the module:
     * - IN_PROGRESS when no student have finished their initial test
     * - ALL_TESTS_DONE when all students have finished their initial test and started the module
     * - INIT_PATH whn some students have finished their initial test and can start their path
     */
    status: "IN_PROGRESS" | "ALL_TESTS_DONE" | "INIT_PATH";
    /** Students who have finished their initial test in the module but not started the module */
    students: Student[];
}

/**
 * Hook to handle the logic of the start path feature
 */
const useStartPath: (classroom: Classroom) => {
    /** List of students in the start path popup */
    studentsReadyForStart: Student[];
    setStudentsReadyForStart: React.Dispatch<React.SetStateAction<Student[]>>;
    modulesState: { [moduleId: string]: ModulePathState } | undefined;
    startPath: () => void;
} = (classroom) => {
    const { config } = useContext(configStore);

    /** Students who appear in the popup to have their path started */
    const [studentsReadyForStart, setStudentsReadyForStart] = useState<
        Student[]
    >([]);
    const [modulesState, setModulesState] =
        useState<{ [moduleId: string]: ModulePathState }>();

    // Init path data
    useEffect(() => {
        const headers = new Headers({
            version: localStorage.getItem<string>(localStorage.Key.VERSION)!,
            authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )!}`,
        });
        fetch(
            `${config.msConfigs!.endpoints.misc!}/classroom/${classroom.id}`,
            { headers }
        )
            .then(
                (response) =>
                    response.json() as Promise<{
                        data: {
                            [moduleId: string]: { students: ResponseStudent[] };
                        };
                    }>
            )
            .then(({ data }) => {
                
                const modulesData: { [moduleId: string]: ModulePathState } = {};

                Object.keys(data).forEach((moduleId) => {
                    const module = classroom.modulesList.find(
                        (module) => module.id === moduleId
                    );
                    if (!module) {
                        // [TODO] Error handling
                        console.log(
                            `Module ${moduleId} not found in classroom`
                        )
                        return;
                    }

                    // Add students names
                    const _students = data[moduleId].students.map((student) => {
                        // if (!module.students[student.id]) {
                        //     // [TODO] Error handling
                        //     console.log(
                        //         `Student ${student.id} not found in classroom module`,
                        //         module.students
                        //     );
                        // }
                        return {
                            ...student,
                            moduleId,
                            firstname: module.students[student.id]?.firstname,
                            lastname: module.students[student.id]?.lastname,
                        };
                    });

                    // Get state of path feature for the module
                    const state = getModuleState(_students);
                    modulesData[moduleId] = state;
                });
                setModulesState(modulesData);

                // On first load, trigger the start path popup for students of all modules who have finished their initial test
                /* setStudentsReadyForStart(
                    Object.keys(modulesData)
                        .map((moduleId) => modulesData[moduleId].students)
                        .flat()
                ); */
            })
            .catch(() => {
                // [TODO] Error handling
            });
            
    }, []);

    return {
        studentsReadyForStart,
        setStudentsReadyForStart,
        modulesState,
        startPath: async () => {
            
            if (!modulesState) return;

            const modulesReadyForStart = studentsReadyForStart.reduce(
                (acc, student) => {
                    if (acc.includes(student.moduleId)) return acc;
                    return [...acc, student.moduleId];
                },
                [] as string[]
            );
            
            setStudentsReadyForStart([]);
            modulesReadyForStart.forEach(async (moduleId) => {
                try {
                    const headers = new Headers({
                        version: localStorage.getItem<string>(
                            localStorage.Key.VERSION
                        )!,
                        authorization: `Bearer ${localStorage.getItem<string>(
                            localStorage.Key.TOKEN
                        )!}`,
                    });
                    const response = await fetch(
                        `${config.msConfigs!.endpoints
                            .misc!}/start_classroom_itinerary/${
                            classroom.id
                        }/${moduleId}`,
                        { method: "POST", headers }
                    );

                    // [TEMP] For dev
                    if (response.status === 204)
                        console.log("Itinerary already started");
                    const responseContent = response.json();
                    console.log("start_itinerary response", responseContent);
                    //

                    setModulesState((curr) => {
                        return {
                            ...curr,
                            [moduleId]: {
                                status: "ALL_TESTS_DONE",
                                students: [],
                            },
                        };
                    });
                } catch {
                    // [TODO] Error handling
                }
            });
        },
    };
};

const getModuleState = (students: Student[]): ModulePathState => {
    // filter students who have finished their inital test
    const studentsReadyForStart = students.filter(
        (student) =>
            student.isClusterAvailable === true &&
            student.moduleStarted === false
    );
    const allTestsDone = students.every(
        (student) => student.moduleStarted === true
    );

    return {
        status:
            studentsReadyForStart.length > 0
                ? "INIT_PATH"
                : allTestsDone
                ? "ALL_TESTS_DONE"
                : "IN_PROGRESS",
        students: studentsReadyForStart,
    };
};

export default useStartPath;
