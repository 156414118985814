import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { configStore } from "../../../../contexts/ConfigContext";
import { Classroom } from "../../../../interfaces/Dashboard";

interface StudentInDifficulty {
    firstname: string;
    lastname: string;
    id: string;
}

const StudentsInDifficulty: React.FC<{
    classroom: Classroom
}> = ({ classroom }) => {
    const { config } = useContext(configStore);

    const [students, setStudents] = useState<StudentInDifficulty[]>([])

    const removeDuplicate = (arr: StudentInDifficulty[]) => {
        let sortedArraya = arr.sort((a: StudentInDifficulty, b: StudentInDifficulty) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        return sortedArraya.filter(function (item, pos, ary) {
            return !pos || item.id != ary[pos - 1].id;
        });
    }

    const getStudentsInDifficulties = () => {
        const studentsInDifficulty: StudentInDifficulty[] = [];
        classroom.modulesList.forEach((module) =>
            Object.keys(module.students).forEach((studentId) => {
                if (module.students[studentId].studentInDifficulty) {
                    studentsInDifficulty.push({
                        firstname: module.students[studentId].firstname,
                        lastname: module.students[studentId].lastname,
                        id: module.students[studentId].id,
                    });
                }
            })
        );
        
        setStudents( removeDuplicate(studentsInDifficulty) )
    }

    useEffect(() => {
        getStudentsInDifficulties()
    }, [])

    return (
        students.length ?
            <div className="students-with-difficulties">
                <div className="title">
                    <span className="material-icons">info</span>
                    {config.i18n.dashboard?.classDetails.titleStudentInDifficulty}
                </div>
                {/** TODO : i18n */}
                <div className="student-list">
                    {students.map(
                        (student: {
                            firstname: string;
                            lastname: string;
                            id: string;
                        }) => (
                            <Link
                                key={student.id}
                                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${classroom.id}/${student.id}`}
                            >
                                <span className="material-icons">person</span>
                                <div className="name">
                                    <span>
                                        {student.firstname} {student.lastname}
                                    </span>
                                    <span>
                                        {config.i18n.dashboard?.common.seeProfile}
                                        <span className="material-icons">
                                            chevron_right
                                        </span>
                                    </span>
                                </div>
                            </Link>
                        )
                    )}
                </div>
            </div>
            : null
    );
};

export default StudentsInDifficulty;
