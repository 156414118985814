import React, { useEffect, useContext, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import "rc-tabs/assets/index.css";
import "rc-tooltip/assets/bootstrap_white.css";
import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";

import { configStore } from "../../../contexts/ConfigContext";
import { dashboardStore } from "../../../contexts/DashboardContext";
import { sessionStore } from "../../../contexts/SessionContext";

import { fetchData, getData } from "../../../utils/request-data";
import * as localStorage from '../../../utils/localStorage';

import { customAlphabet, urlAlphabet } from 'nanoid'

import Loader from "../../../components/Loader/Loader";
import Tabs from "./Tabs/Tabs";

import "./ClassList.scss";

import { Dashboard } from "../../../interfaces/Dashboard";
import { Classroom } from "../../../interfaces/User";

interface RegisterState {
  step: 'register' | 'saving' | 'saved';
  classCode?: string;
}

const ClassList: React.FC<Dashboard> = () => {
  const { config } = useContext(configStore);
  const { session } = useContext(sessionStore);
  const { dashboard: { classrooms, clustering }, setDashboard } = useContext(dashboardStore);
  const [open, setOpen] = React.useState(false);
  const [registerClassState, setRegisterClassState] = useState<RegisterState>({ step: 'register' })

  const onModalClose = () => {
    setOpen(false);
    setRegisterClassState({ step: "register", classCode: undefined })
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onSubmit = async (text: string) => {

    setRegisterClassState({ step: "saving" })

    let postHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "Content-Type": "application/json"
    })
    let getHeaders = new Headers({
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "x-evb-origin": window.location.origin,
      "version": config.declinaison
    })

    const nanoid = customAlphabet(urlAlphabet, 10)
    let body = {
      external_id: nanoid(),
      provider: config.declinaison,
      name: text,
      level: 'none',
      school: '',
      teachers: [session.userId],
      students: [],
      extra: 'none'
    }
    let createClass = await fetchData<Classroom>(config.msConfigs?.endpoints.users + '/api/classrooms/', 'POST', body, postHeaders)
    console.log("API: CLASSROOMS: POST", createClass);
    const dashboard = await getData<any>(config.msConfigs?.endpoints.analytics + "/teacher/" + session.userId + "/classrooms_analytics/", getHeaders)
    setDashboard(dashboard)
    setRegisterClassState({ step: "saved", classCode: body.external_id })
  };

  return (
    <div className="classrooms-list-container">
      <h1>{config.i18n.dashboard?.common.classes}</h1>
      <div className="classrooms-list">

        {classrooms.map((classroom, i) => (
          <Tabs key={classroom.id} classroom={classroom} clustering={config.features.clustering ? clustering![classroom.id] : undefined} />
        ))}

        {
          config.features.classManagement ?
            <div className="create-class" onClick={onOpenModal}>
              <span className="material-icons add-icon">add_circle_outline</span>
              <span className="message">{config.i18n.dashboard?.classList.form.add}</span>
            </div>
            : null
        }

        <Dialog
          closable={registerClassState.step === "saving" ? false : true}
          maskClosable={registerClassState.step === "saving" ? false : true}
          title={
            <div className="modal-title">
              <>
                {
                  registerClassState.step === "register" ?
                    <span className="material-icons add-icon">add_circle_outline</span> :
                    registerClassState.step === "saved" ?
                      <span className="material-icons success-icon">check_circle</span> :
                      null
                }
              </>
              <span className="title">{
                registerClassState.step === "saving" ?
                  <span>
                    {config.i18n.dashboard?.classList.form.saving}
                  </span> :
                  registerClassState.step === "saved" ?
                    <span>
                      {config.i18n.dashboard?.classList.form.success}
                    </span> :
                    config.i18n.dashboard?.classList.form.new
              }</span>
            </div>
          }
          visible={open}
          onClose={onModalClose}
        >
          <NewClass onCancel={onModalClose} onSubmit={onSubmit} state={registerClassState} />
        </Dialog>
      </div>
    </div>
  );
};

const NewClass: React.FC<{
  onCancel: () => void;
  onSubmit: (value: string) => void;
  state: RegisterState;
}> = ({ onCancel, onSubmit, state }) => {
  const { config } = useContext(configStore);
  const [textValue, setTextValue] = React.useState("");

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTextValue(event.currentTarget.value);
  };

  const goNextStep = () => {
    onSubmit(textValue)
  }

  return (
    <div className="new-class-container">
      {
        state.step === "register" ?
          <div className="step step-1">
            <span className="label">{config.i18n.dashboard?.classList.form.name}</span>
            <input
              className="input"
              name="name"
              onChange={onInputChange}
              type="text"
            />
            <div className="buttons">
              <div className="add" onClick={goNextStep}>
                {config.i18n.dashboard?.classList.form.add}
              </div>
              <span className="cancel" onClick={onCancel}>
                {config.i18n.dashboard?.classList.form.cancel}
              </span>
            </div>
          </div> :
          state.step === "saving" ? <div className="step step-2"><Loader /></div> :
            state.step === "saved" ?
              <div className="step step-3">
                <div className="message">
                  <span className="label">{config.i18n.dashboard?.classList.form.code}</span>
                  <span className="code">{state.classCode}</span>
                  <p>{config.i18n.dashboard?.classList.form.message}</p>
                </div>
                <div className="buttons">
                  <div className="add" onClick={onCancel}>
                    {config.i18n.dashboard?.classList.form.continue}
                  </div>
                </div>
              </div> : null
      }
    </div>
  );
};

export default ClassList;
