import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { configStore } from "../../../../contexts/ConfigContext";

import "./ProgressBarLink.scss";

type ProgressBarLinkProps = {
    url: string;
    progression: number | string;
    title: string | undefined;
    displayProgressionLabel?: boolean;
    group?: number;
};

const ProgressBarLink: React.FC<ProgressBarLinkProps> = ({
    url,
    progression,
    title,
    displayProgressionLabel = true,
    group,
}) => {
    const { config } = useContext(configStore);

    return (
        <Link to={url} className="progress-bar-link">
            <div
                className="progressBar"
                style={{ width: progression + "%" }}
            ></div>
            <div className="progress-bar-text">
                <div className="module-title">{title}</div>
                {displayProgressionLabel ? (
                    <div className="module-progression">{`${progression} %`}</div>
                ) : (
                    <div className="group">{`${group} ${group === 1 ? config.i18n.dashboard?.classDetails.identifiedGroup : config.i18n.dashboard?.classList.identifiedGroups}`}</div>
                )}
            </div>
        </Link>
    );
};

export default ProgressBarLink;
