import React, { MouseEvent } from "react";
import Loader from "../Loader/Loader";

import "./Button.scss";

interface ButtonProps {
    style: "primary" | "secondary" | "tertiary";
    centeredLabel?: boolean;
    label: string | undefined;
    loader?: boolean
    icon?: string;
    onClick?: (e: MouseEvent) => void;
    disabled?:boolean;
}

const Button = ({
    style,
    label,
    icon,
    centeredLabel,
    onClick,
    disabled,
    loader
}: ButtonProps) => {
    return (
        <button
            className={`btn ${style} ${centeredLabel ? "centered" : ""} ${disabled ? "disabled" : ""}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span className="label">{label}</span>
           {loader ? <Loader /> : null}
            {icon ? (
                <span className="icon-container-btn">
                    <span className="material-icons">{icon}</span>
                </span>
            ) : null}
        </button>
    );
};

export default Button;
