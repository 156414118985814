import { HistoryItem } from "@evidenceb/bandit-manchot";
import { HierarchyIds } from "@evidenceb/gameplay-interfaces";
import { Activity, Agent, Statement } from "@xapi/xapi";
import { v4 as uuid } from "uuid";

const VERBS = {
    failed: {
        id: "http://adlnet.gov/expapi/verbs/failed",
        display: {
            "en-US": "failed",
        },
    },
    passed: {
        id: "http://adlnet.gov/expapi/verbs/passed",
        display: {
            "en-US": "passed",
        },
    },
};
const XAPI_REGISTRY = "http://xapiressource.prod.evidenceb-services.com";

export const durationToISO = (duration: number): string => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = (duration % 3600) % 60;
    if (hours > 0) {
        return `PT${hours}H${minutes}M${seconds}S`;
    } else if (minutes > 0) {
        return `PT${minutes}M${seconds}S`;
    } else {
        return `PT${seconds}S`;
    }
};

export const getCurrentAgent = (declinaison: string, evidencebId: string): Agent => {
    return {
        account: {
            name: evidencebId,
            homePage: `${XAPI_REGISTRY}/homepages/${declinaison}`,
        },
        objectType: "Agent",
    };
};

export const statementsToHistory = (statements: Statement[]): HistoryItem[] => {
    return statements
        .sort((a, b) => {
            if (!a.timestamp) return -1;
            if (!b.timestamp) return 1;
            return Date.parse(a.timestamp) - Date.parse(b.timestamp);
        })
        .map((statement) => {
            return {
                exerciseId: objectToExerciseId(statement.object as Activity),
                score: statement.result!.score!.raw!,
                // No need to add duration and timestamp as they are only used
                // for the full diagnosis
            };
        });
};

export const contextToHierarchyId = (
    context: Required<Statement>["context"]
): Omit<HierarchyIds, "exerciseId" | "isInitialTest"> => {
    const re = new RegExp(
        `${XAPI_REGISTRY}/module/(.*?)/objective/(.*?)/activity/(.*)`,
        ""
    );
    const result = re[Symbol.match](context.contextActivities!.parent![0].id);
    return {
        moduleId: result![1],
        objectiveId: result![2],
        activityId: result![3],
    };
};

export const objectToExerciseId = (object: Activity): string => {
    const re = new RegExp(`${XAPI_REGISTRY}/exercise/(.*)`);
    const result = re[Symbol.match](object.id);
    return result![1];
};

export const filterStatementsByModule = (
    statements: Statement[],
    targetModuleId: string
): Statement[] => {
    return statements.filter((statement) => {
        const { moduleId } = contextToHierarchyId(statement.context!);
        return moduleId === targetModuleId;
    });
};

export const makeStatement = (
    historyItem: HierarchyIds & {
        score: number;
        success: boolean;
        isInitialTest: boolean;
        answer?: any;
        duration: number;
    },
    sessionId: string,
    evidencebId: string,
    declinaison: string
): Statement => {
    return {
        timestamp: new Date().toISOString(),
        id: uuid(),
        actor: getCurrentAgent(declinaison, evidencebId),
        verb: historyItem.success ? VERBS.passed : VERBS.failed,
        object: {
            id: XAPI_REGISTRY + "/exercise/" + historyItem.exerciseId,
            objectType: "Activity",
        },
        result: {
            score: {
                scaled: historyItem.score,
                raw: historyItem.score,
                min: 0,
                max: 1,
            },
            success: historyItem.success,
            response: JSON.stringify(historyItem.answer),
            duration: durationToISO(historyItem.duration / 1000),
        },
        context: {
            registration: sessionId,
            contextActivities: {
                parent: [
                    {
                        id: `${XAPI_REGISTRY}/module/${historyItem.moduleId}/objective/${historyItem.objectiveId}/activity/${historyItem.activityId}`,
                        objectType: "Activity",
                    },
                ],
            },
            extensions: {
                [`${XAPI_REGISTRY}/extensions/common`]: {
                    initialTest: historyItem.isInitialTest,
                },
            },
        },
    };
};
