import React, { useEffect, useState, useContext, FormEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "../../../utils/parse-text";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { homeStore } from "../../../contexts/HomeContext";
import { Helmet } from "react-helmet";
import { Config, Title } from "../../../interfaces/Config";
// Assets
import HomeContent from "../HomeContent/HomeContent";
import Button from "../../../components/Button/Button";
import { fetchData, getData } from "../../../utils/request-data";
import { Classroom, User } from "../../../interfaces/User";
import * as localStorage from "../../../utils/localStorage";
import "./HomeStudent.scss";

interface IUser {
    first_name: string;
    last_name: string;
}

interface IClassroom {
    id:string;
    code: string;
}

function HomeStudent({ title }: Title) {

    const { pathname } = useLocation();
    const { config, setConfig } = useContext(configStore);
    const { home } = useContext(homeStore);
    const { session } = useContext(sessionStore);
    const [state, setState] = useState<{ user: IUser; classroom: IClassroom }>({
        classroom: {
            id:"",
            code: "",
        },
        user: {
            first_name: "",
            last_name: "",
        },
    });

    const [displayClassRegistration, setDisplayClassRegistration,] = useState<boolean>(config.auth.mode === "REGISTER");
    const [displaySignIn, setDisplaySignIn] = useState<boolean>(config.auth.mode === "REGISTER_NAMEONLY");
    const [displayHomeContent, setDisplayHomeContent] = useState<boolean>(!config.auth.displaySignIn);
    const [displayError, setDisplayError] = useState<boolean>();
    const [status, setStatus] = useState<"idle" | "saving">("idle")

    const token = localStorage.getItem<string>(localStorage.Key.TOKEN);

    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    });

    const registerChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        setState({
            ...state,
            classroom: { ...state.classroom, code: e.currentTarget.value },
        });
        if (displayError) {
            setDisplayError(false);
        }
    };

    const registerClassroomHandler = async () => {
        setStatus("saving")
        let params = `?provider=${session.appProvider}&external_id=${state.classroom.code}`;
        const classrooms = await getData<Classroom[]>( config.msConfigs?.endpoints.users + "/api/classrooms/" + params, headers );
        if (classrooms.length) {   
            setState({
                ...state,
                classroom: {
                    ...state.classroom,
                    id: classrooms[0].id
                }
            });
            setDisplayClassRegistration(false);
            setDisplayError(false);
            setDisplaySignIn(true);
        } else {
            setDisplayError(true);
        }
        setStatus("idle")
    };

    const signInChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        setState({
            classroom:{
                ...state.classroom
            },
            user: {
                ...state.user,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
        if (displayError) {
            setDisplayError(false);
        }
    };

    const signInHandler = async () => {
        
        const user = await getData<User>( config.msConfigs?.endpoints.users + "/api/students/" + session.userId, headers );
        if (user) {
            let classroomsUpdated = user.classrooms;
            classroomsUpdated.push(state.classroom.id)
            let userBody = {
                first_name: state.user.first_name,
                last_name: state.user.last_name,
                classrooms: classroomsUpdated,
            };
            const userUpdated = await fetchData<User>( config.msConfigs?.endpoints.users + "/api/students/" + user.id + "/", "PATCH", userBody, headers );

            if (userUpdated) {
                console.log( `API: PATCH: USER (student)`, userUpdated );
                let updatedConfig: Config = {
                    ...config,
                    auth: { ...config.auth, displaySignIn: false },
                };
                setConfig(updatedConfig);
                setDisplayHomeContent(true)
            }
        }
    };

    const getUserData = async () => {
        const user = await getData<User>( config.msConfigs?.endpoints.users + "/api/students/" + session.userId, headers );
        return user;
    }

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [pathname]);

    useEffect(() => {
        getUserData()
        .then( user => {
            setState({
                classroom: {
                    id:"",
                    code: "",
                },
                user: {
                    first_name: user.first_name,
                    last_name: user.last_name,
                },
            })
        })
    }, []);

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div id="home-student">
                {
                    config.auth.displaySignIn ?
                        <>
                            <div className="block left">
                                <img src={config.logos.header_client} />
                                {displayClassRegistration && (
                                    <Register
                                        state={state}
                                        registerChangeHandler={registerChangeHandler}
                                        registerClassroomHandler={registerClassroomHandler}
                                        displayError={displayError}
                                        status={status}
                                    />
                                )}
                               
                                {displaySignIn && (
                                    <SignIn
                                        state={state}
                                        signInChangeHandler={signInChangeHandler}
                                        signInHandler={signInHandler}
                                        status={status}
                                    />
                                )}
                            </div>
                            <div className="block right">
                                <img src={config.auth.rightImage} />
                            </div>
                        </>
                        : null
                }

                {
                    displayHomeContent ?
                        <HomeContent content={home.student} />
                    : null
                }

            </div>
        </>
    );
}

const Info = () => {
    const { config } = useContext(configStore);

    return (
        <div className="info">
            <span className="material-icons">help_outline</span>
            <span className="text">
                {parse(
                    config.i18n.auth.student.registerClass.classCode.info.$html
                )}
            </span>
        </div>
    );
};

const Register: React.FC<{
    state: { user: IUser; classroom: IClassroom };
    registerChangeHandler: (e: FormEvent<HTMLInputElement>) => void;
    registerClassroomHandler: () => Promise<void>;
    displayError?: boolean;
    status:"idle" | "saving";
}> = ({
    state,
    registerChangeHandler,
    registerClassroomHandler,
    displayError,
    status
}) => {
        const { config } = useContext(configStore);

        return (
            <div className="register-classroom">
                <div className="header">
                    <h1>{config.i18n.auth.student.registerClass.title}</h1>
                    <div className="subtitle">
                        <span>
                            {parse(
                                config.i18n.auth.student.registerClass.text.$html
                            )}
                        </span>
                        <Info />
                    </div>
                </div>

                <div className="form">
                    <input
                        className="code"
                        type="text"
                        placeholder={config.i18n.auth.form.classCode}
                        name="classroom"
                        value={state.classroom.code}
                        onChange={registerChangeHandler}
                    />
                    {displayError ? (
                        <div className="error">
                            <span className="material-icons">info</span>
                            <span className="text">
                                {parse(
                                    config.i18n.auth.form.error.codeClassError.$html
                                )}
                            </span>
                        </div>
                    ) : null}
                    <Button
                        style="tertiary"
                        centeredLabel={true}
                        label={config.i18n.auth.form.button.next}
                        loader={status === "saving"}
                        disabled={state.classroom.code === "" || status === "saving"}
                        onClick={registerClassroomHandler}
                    />
                </div>
            </div>
        );
    };

const SignIn: React.FC<{
    state: { user: IUser; classroom: IClassroom };
    signInChangeHandler: (e: FormEvent<HTMLInputElement>) => void;
    signInHandler: () => Promise<void>;
    status: "idle" | "saving"
}> = ({ state, signInChangeHandler, signInHandler, status }) => {
    const { config } = useContext(configStore);

    return (
        <div className="signIn">
            <div className="header">
                <p>{parse(config.i18n.auth.student.signIn.text.$html)}</p>
            </div>
            <div className="form">
                <input
                    type="text"
                    placeholder={config.i18n.auth.form.name}
                    name="last_name"
                    value={state.user.last_name}
                    onChange={signInChangeHandler}
                />
                <input
                    type="text"
                    placeholder={config.i18n.auth.form.firstname}
                    name="first_name"
                    value={state.user.first_name}
                    onChange={signInChangeHandler}
                />
                <Button
                    style="tertiary"
                    centeredLabel={true}
                    label={config.i18n.auth.form.button.start}
                    disabled={
                        state.user.last_name === "" ||
                        state.user.first_name === "" ||
                        status === "saving"
                    }
                    loader={status === "saving"}
                    onClick={signInHandler}
                />
            </div>
        </div>
    );
};

export default HomeStudent;
