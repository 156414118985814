import { HistoryItem, History } from "@evidenceb/bandit-manchot";
import { CaptureContext } from "@sentry/types";

/**
 * Any key must have a type that complies with { [item: string]: string } or
 * implement a specific strategy in the getContext info item switch
 */
interface ContextInfo {
    "Bandit Manchot"?: {
        moduleId?: string;
        history?: HistoryItem[] | History;
    };
    Config?: {
        url?: string;
    };
}

export const getContext = (info?: ContextInfo): CaptureContext => {
    const contexts: {
        [contextTitle: string]: { [item: string]: string | undefined };
    } = {};

    if (info)
        Object.keys(info).forEach((item) => {
            if (item === "Bandit Manchot") {
                contexts[item] = {
                    moduleId: info[item]!.moduleId,
                    history:
                        typeof info[item]!.history !== undefined
                            ? JSON.stringify(info[item]!.history)
                            : undefined,
                };
            } else {
                // @ts-ignore because you followed the warning concerning the
                // ContextInfo interface
                contexts[item] = info[item];
            }
        });
    return { contexts };
};
