import React, { useState, useContext } from 'react'

//Interface
import { Classroom, ClusterError, ModuleCluster } from '../../../../interfaces/Dashboard';

//Contexts
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";

//styles
import './Tabs.scss'

import ProgressBarLink from '../ProgressBarLink/ProgressBarLink';
import { getModuleById, getUrl } from '../../../../utils/dataRetrieval';
import { Link } from 'react-router-dom';
import { sessionStore } from '../../../../contexts/SessionContext';

interface Tabs {
    classroom: Classroom;
    clustering?: {[moduleId: string]: ModuleCluster | ClusterError};
}

function Tabs({ classroom, clustering}: Tabs) {

    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);

    const [tab, setTab] = useState<"tab1" | "tab2">("tab1")

    return (
        <div className="tabs-container">
            <div className="buttons">
                <button
                    className={tab === "tab1" ? "active" : ""}
                    onClick={() => setTab("tab1")}
                >
                    <span>{classroom.name}</span>
                </button>
                {config.features.clustering ? (
                    <button
                        className={tab === "tab2" ? "active" : ""}
                        onClick={() => setTab("tab2")}
                    >
                        <span>{config.i18n.dashboard?.common.groups}</span>
                    </button>
                ) : null}
            </div>
            <div className="tab-content">
                <section
                    id="tab-item-1"
                    className={tab === "tab1" ? "active" : ""}
                >
                    <div className="module">
                        <div className="classInfo">
                            {config.features.classCode && (
                                <div className="classCode">
                                    {config.i18n.dashboard?.classDetails.code}{" "}
                                    {classroom.external_id}
                                </div>
                            )}

                            <Link
                                className="classLink"
                                to={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroom.id}`}
                            >
                                <span className="text">{config.i18n.dashboard?.classList.seeClassDetails}</span>{" "}
                                <span className="material-icons icon">
                                    {" "}
                                    chevron_right{" "}
                                </span>
                            </Link>
                        </div>
                        {classroom.modulesList.length ? (
                            classroom.modulesList.map((module, i) => (
                                <ProgressBarLink
                                    key={classroom.id + module.id}
                                    url={`/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroom.id}/${module.id}`}
                                    progression={Math.round(
                                        module.progression * 100
                                    )}
                                    title={`${getModuleById(module.id, data).title.short}. ${getModuleById(module.id, data).title.long}`}
                                />
                            ))
                        ) : (
                            <div className="no-data">
                                {config.i18n.dashboard?.common.noStudentsData}
                            </div>
                        )}
                    </div>
                </section>
                <section
                    id="tab-item-2"
                    className={tab === "tab2" ? "active" : ""}
                >
                    {config.features.clustering && (
                        classroomHasClustering(classroom, clustering) ? (
                            classroom.modulesList.map((module) => (
                                <>
                                    {clustering![module.id] && (
                                        <ProgressBarLink
                                            key={classroom.id + module.id}
                                            url={`/${getUrl(
                                                config.pages.find(
                                                    (page) =>
                                                        page.type ===
                                                        "DASHBOARD"
                                                )!,
                                                session.userType
                                            )}/${
                                                config.i18n.dashboard?.paths
                                                    .classes
                                            }/${classroom.id}/${module.id}/${
                                                config.i18n.dashboard?.paths
                                                    .groups
                                            }`}
                                            progression={Math.round(
                                                module.progression * 100
                                            )}
                                            title={`${getModuleById(module.id, data).title.short}. ${getModuleById(module.id, data).title.long}`}
                                            displayProgressionLabel={false}
                                            group={
                                                typeof clustering![module.id]
                                                    .error === "undefined"
                                                    ? (
                                                          clustering![
                                                              module.id
                                                          ] as ModuleCluster
                                                      ).infosClustering
                                                          .nbClusters
                                                    : undefined
                                            }
                                        />
                                    )}
                                </>
                            ))
                        ) : (
                            <div className="no-data">
                                {config.i18n.dashboard?.common.noStudentsData}
                            </div>
                        )
                    )}
                </section>
            </div>
        </div>
    );
}

const classroomHasClustering = (classroom: Classroom, clustering: {[moduleId: string]: ModuleCluster | ClusterError} | undefined): boolean => {
    if (!clustering) return false;

    // If there are only modules with 0 clusters, return false
    const isThereOneCluster = classroom.modulesList.reduce((isThereOneCluster, module) => {
        if (isThereOneCluster) return true;

        if (!clustering[module.id] || typeof clustering[module.id].error !== "undefined" || (clustering [module.id] as ModuleCluster).infosClustering.nbClusters === 0)
            return false;
        return true;
    }, false);

    return isThereOneCluster;
}

export default Tabs
