import React from "react";
import { useHistory } from "react-router-dom";

// Reference for accessibility: https://www.aditus.io/patterns/breadcrumbs/

import "./Breadcrumbs.scss";

const Breadcrumbs: React.FC<{
    items: { title: string; url: string; current?: boolean }[];
}> = ({ items }) => {
    const history = useHistory();

    return (
        <nav aria-label="breadcrumbs" className="breadcrumbs">
            {items.map((item, index) => (
                <>
                    <a
                        onClick={() => {
                            history.push(item.url);
                        }}
                        key={`breadcrumbs-item-${index}`}
                        aria-current={item.current ? "location" : false}
                        className="breadcrumbs__item"
                    >
                        {item.title}
                    </a>
                    {index !== items.length - 1 && (
                        <div
                            aria-hidden="true"
                            className="breadcrumbs__separator"
                        >
                            –
                        </div>
                    )}
                </>
            ))}
        </nav>
    );
};

export default Breadcrumbs;
