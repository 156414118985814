import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { BanditManchot, History } from "@evidenceb/bandit-manchot";

export interface Session {
    banditManchot: BanditManchot;
    initialHistory?: History;
    sessionId: string;
    userId: string;
    externalId: string;
    userProvider: string;
    appProvider: string;
    userType: "TEACHER" | "STUDENT";
    evidencebId: string;
    flags: {
        useHistoryFrom: "localHistory" | "LRS";
    };
}

interface SessionContext {
    session: Session;
    setSession: Dispatch<SetStateAction<Session>>;
}

const initialState: Session = {
    banditManchot: {},
    initialHistory: undefined,
    sessionId: "",
    userId:"",
    externalId: "",
    userProvider:"",
    appProvider: "",
    userType: "STUDENT",
    evidencebId: "",
    flags: { useHistoryFrom: "LRS" },
};

// Context init
const sessionStore = createContext<SessionContext>({
    session: initialState,
    setSession: () => null,
});

// Provider init
const { Provider } = sessionStore;

// Final setup
const SessionProvider = ({ children }: { children: JSX.Element }) => {
    const [session, setSession] = useState(initialState);
    return <Provider value={{ session, setSession }}>{children}</Provider>;
};

export { sessionStore, SessionProvider };
