import { HtmlString } from '@evidenceb/gameplay-interfaces';
import React, {useContext, MouseEvent} from 'react';
import {configStore} from "../../contexts/ConfigContext";
import { parse } from '../../utils/parse-text';
import './DemoBanner.scss';

interface DemoBannerProps {
    message: HtmlString | undefined;
    position: 'top' | 'right' | 'custom'
}

function DemoBanner( { message, position }: DemoBannerProps) {
    const {config} = useContext(configStore)
    const toggle = (e: MouseEvent<HTMLDivElement>) => {
        e.currentTarget?.classList.toggle('opened')
    }
    return (
        <div className={`demo-banner opened ${position}`} onClick={toggle}>
            <button>
                <span className="material-icons open">chevron_right</span>
                <span className="material-icons close">chevron_left</span>
            </button>
            <img className="logo" src={config.logos.demo} />
            <p>{parse(message!.$html)}</p>
        </div>
    )
}

export default DemoBanner
