import React from "react";
import styled from "styled-components/macro";

const ProgressionIcon: React.FC = () => {
    return (
        <Wrapper>
            <Inner />
            <Bar>
                <Progress left />
            </Bar>
            <Bar right>
                <Progress />
            </Bar>
        </Wrapper>
    );
};

const Wrapper = styled.span`
    display: block;
    * {
        display: block;
    }
    position: relative;
    height: 32px;
    width: 32px;
`;

const Inner = styled.span`
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    transform: translate(-50%, -50%);
    background: #f2f6fb;
    border-radius: 100%;
`;

const Progress = styled.span<{ left?: boolean }>`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background: var(--color-primary);
    clip: rect(0px, 16px, 32px, 0px);

    ${(p) =>
        p.left &&
        `
    z-index: 1;
    transform: rotate(90deg);
    `}
`;

const Bar = styled.span<{ right?: boolean }>`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background: rgba(0,0,0,0.2);
    clip: rect(0px, 32px, 32px, 16px);

    ${(p) =>
        p.right &&
        `
    transform: rotate(180deg);
    z-index: 3;
    `}
`;

export default ProgressionIcon;
