import React, { Dispatch, SetStateAction } from "react";
import DashboardActionButton from "../../../../components/DashboardActionButton/DashboardActionButton";
import { ModulePathState, Student } from "./useStartPath";
import ProgressionIcon from "./ProgressionIcon";

interface Props extends ModulePathState {
    setStudentsReadyForStart: Dispatch<SetStateAction<Student[]>>;
}

/**
 * Component that displays the button to handle the start path feature
 */
const ModuleHandler = ({
    status,
    students,
    setStudentsReadyForStart,
}: Props) => {
    return (
        <>
            {status === "IN_PROGRESS" && (
                <DashboardActionButton Icon={ProgressionIcon} disabled className="in-progress-module-handler">
                    Tests diagnostiques
                    <br />
                    en cours
                </DashboardActionButton>
            )}

            {status === "INIT_PATH" && (
                <DashboardActionButton
                    materialIcon="play_circle_outline"
                    onClick={() => setStudentsReadyForStart(students)}
                >
                    <span style={{fontWeight: 600, color: "var(--color-primary)"}}>Démarrer les parcours</span>
                    <br />
                    <span style={{ fontSize: "0.8em" }}>{`(${
                        students.length > 1
                            ? students.length + " élèves"
                            : students.length + " élève"
                    } en attente)`}</span>
                </DashboardActionButton>
            )}
        </>
    );
};

export default ModuleHandler;
