import React, { useEffect, useContext, FormEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { homeStore } from "../../../contexts/HomeContext";
import { sessionStore } from "../../../contexts/SessionContext";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { configStore } from "../../../contexts/ConfigContext";
import { Config, Title } from "../../../interfaces/Config";
import { User } from "../../../interfaces/User";
import { fetchData, getData } from "../../../utils/request-data";
import Button from "../../../components/Button/Button";
import * as localStorage from "../../../utils/localStorage";
import HomeContent from '../HomeContent/HomeContent';
import "./HomeTeacher.scss";

interface IUser {
    first_name: string;
    last_name: string;
}

function HomeTeacher({ title }: Title) {
    const { home } = useContext(homeStore);
    const { pathname } = useLocation();
    const { config, setConfig } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const [state, setState] = useState<IUser>({
        first_name:'',
        last_name:''
    })
    const [displayError, setDisplayError] = useState<boolean>();
    const [displaySignIn, setDisplaySignIn] = useState<boolean>();
    const [displayHomeContent, setDisplayHomeContent] = useState<boolean>(!config.auth.displaySignIn);
    const [status, setStatus] = useState<"idle" | "saving">("idle")

    const token = localStorage.getItem<string>(localStorage.Key.TOKEN);
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    });

    const signInChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        setState({
            ...state!,
            [e.currentTarget.name]: e.currentTarget.value
        })
        if (displayError) {
            setDisplayError(false)
        }
    }

    const signInHandler = async () => {
        setStatus("saving")
        const user = await getData<User>(
            config.msConfigs?.endpoints.users + '/api/teachers/' + session.userId, headers
        );
        if (user) {
            let userBody = {
                first_name: state?.first_name,
                last_name: state?.last_name,
            }

            const userUpdated = await fetchData<User>(config.msConfigs?.endpoints.users + '/api/teachers/' + user.id + '/', "PATCH", userBody, headers);

            if (userUpdated.first_name !== "" && userUpdated.last_name !== "") {
                console.log(`API: PATCH: USER (teachers)`, userUpdated);
                let updatedConfig: Config = { ...config, auth: { ...config.auth, displaySignIn: false } }
                setConfig(updatedConfig)
                setDisplaySignIn(false)
                setDisplayHomeContent(true)
            }
        }
        setStatus("idle")
    }

    const getUserData = async () => {
        const user = await getData<User>(config.msConfigs?.endpoints.users + "/api/teachers/" + session.userId, headers);
        return user;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const showSignIn = config.auth.displaySignIn
        if(showSignIn){
            getUserData()
            .then(user => {
                setState({
                    first_name: user.first_name,
                    last_name: user.last_name,
                })
            })
            setDisplaySignIn(showSignIn)
        }

    }, []);

    const signIn =
        <div className="signIn-container">
            <div className="block left">
                <img src={config.logos.header_client} />
                <div className="signIn">
                    <div className="header">
                        <h1>{config.i18n.auth.teacher.signIn.title}</h1>
                        <p>{parse(config.i18n.auth.teacher.signIn.text.$html)}</p>
                    </div>
                    <div className="form">
                        <input type="text" placeholder={config.i18n.auth.form.name} name="last_name" value={state?.last_name} onChange={signInChangeHandler} />
                        <input type="text" placeholder={config.i18n.auth.form.firstname} name="first_name" value={state?.first_name} onChange={signInChangeHandler} />
                        <Button
                            style="tertiary"
                            centeredLabel={true}
                            label={config.i18n.auth.form.button.start}
                            disabled={state?.last_name === "" || state?.first_name === "" || status === "saving"}
                            loader={status === "saving"}
                            onClick={signInHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="block right">
                <img src={config.auth?.rightImage} />
            </div>
        </div>

    return (
        <>
            <Helmet> {" "} <title>{title}</title>{" "} </Helmet>
            <div id="home-teacher">
                {
                    config.auth?.mode === "REGISTER" || config.auth?.mode === "REGISTER_NAMEONLY" ?
                        displaySignIn ? signIn : null
                        : null
                }
                {
                    displayHomeContent ? <HomeContent content={home.teacher} /> : null
                }
            </div>
        </>
    );
}

export default HomeTeacher;
