import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    BanditManchotWhisperer,
    History,
} from "@evidenceb/bandit-manchot";
// Contexts
import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";
// Assets
import "./ModuleListStudent.scss";
import parse from "html-react-parser";
import Loader from "../../components/Loader/Loader";
import {
    filterStatementsByModule,
    statementsToHistory,
} from "../../utils/tracking";
import * as Sentry from "@sentry/react";
import { getContext } from "../../utils/sentry";
import { sessionStore } from "../../contexts/SessionContext";
import { Helmet } from "react-helmet";
import { Title } from "../../interfaces/Config";
import { getStudentBasicDiagnostic } from "@evidenceb/bandit-manchot/build/bandit-manchot-whisperer";
import useStatements from "../../hooks/useStatements";

function ModuleListStudent({ title }: Title) {
    const [hasSuccessRate, setHasSuccessRate] = useState(false);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const {
        session: { banditManchot, initialHistory, flags },
        setSession,
    } = useContext(sessionStore);
    const history = useHistory();
    const { getResultStatements } = useStatements();
    const [loading, setLoading] = useState<boolean>(true);
    const [diagnostics, setDiagnostics] = useState<{
        [moduleId: string]: {
            progression: number;
            averageScore: number;
        };
    }>();

    const getModuleTitle = (id: string) => {
        let isModule = data.modules.find((m) => m.id === id);
        if (isModule) {
            return isModule.title.short;
        } else {
            return "no title";
        }
    };

    // Init Bandit Manchot
    useEffect(() => {
        setLoading(true);

        if (!initialHistory) {
            getResultStatements()
                .then((statements) => {
                    const history: History = {};
                    Object.keys(banditManchot).forEach((moduleId) => {
                        history[moduleId] = statementsToHistory(
                            filterStatementsByModule(statements, moduleId)
                        );
                    });
                    const loadedBM = BanditManchotWhisperer.loadAllModulesHistory(
                        banditManchot,
                        history,
                        (err, moduleId) => {
                            // TODO: what to do when loading fails ?
                            console.error(
                                `Cannot load history for module ${moduleId}: ` +
                                    err
                            );
                            Sentry.captureException(
                                `Cannot load history for module ${moduleId}: ` +
                                    err,
                                getContext({
                                    "Bandit Manchot": {
                                        history: history[moduleId],
                                    },
                                })
                            );
                        }
                    );
                    setSession((curr) => {
                        return {
                            ...curr,
                            banditManchot: loadedBM,
                            initialHistory: history,
                        };
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    // TODO: How do we signal to the user that their history was not retrieved?
                    console.error(
                        `Err: Cannot get history statements from ${flags.useHistoryFrom}: ` +
                            err
                    );
                    Sentry.captureException(
                        `Err: Cannot get history statements from ${flags.useHistoryFrom}: ` +
                            err,
                        getContext()
                    );
                    setLoading(false);
                });
        } else {
            const loadedBM = BanditManchotWhisperer.loadAllModulesHistory(
                banditManchot,
                initialHistory,
                (err, moduleId) => {
                    // TODO: what to do when loading fails ?
                    console.error(
                        `Cannot load history for module ${moduleId}: ` + err
                    );
                    Sentry.captureException(
                        `Cannot load history for module ${moduleId}: ` + err,
                        getContext({
                            "Bandit Manchot": {
                                history: initialHistory[moduleId],
                            },
                        })
                    );
                }
            );
            setSession((curr) => {
                return { ...curr, banditManchot: loadedBM };
            });
            setLoading(false);
        }
    }, [initialHistory]);

    // Get diagnostic when BM is loadded
    useEffect(() => {
        const diag: {
            [moduleId: string]: {
                progression: number;
                averageScore: number;
            };
        } = {};
        Object.keys(banditManchot).forEach((moduleId) => {
            diag[moduleId] = getStudentBasicDiagnostic(
                banditManchot[moduleId].instance
            );
        });
        setDiagnostics(diag);
    }, [banditManchot]);

    if (loading || !diagnostics) return <Loader />;

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div id="module-list">
                <div id="welcome-message">
                    <img
                        className="avatar-img"
                        src={config.logos.avatar}
                        alt=""
                    />
                    <div className="text-content">
                        {hasSuccessRate
                            ? parse(
                                  config.i18n.moduleList.student.message.visited
                                      .$html
                              )
                            : parse(
                                  config.i18n.moduleList.student.message.default
                                      .$html
                              )}
                    </div>
                </div>
                <div id="card-container">
                    {Object.keys(banditManchot).map((key, index) => {
                        return (
                            <div className="card" key={`card-${key}`}>
                                <div
                                    className={`title ${
                                        diagnostics[key].progression
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    
                                    {getModuleTitle(key)}
                                </div>
                                <div className="card-content">
                                    <div className="bar-container">
                                        <div
                                            className={`bar ${
                                                diagnostics[key].progression
                                                    ? "progress-active"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${diagnostics[key].progression}%`,
                                                }}
                                            >
                                                <div className="text-content">
                                                    {
                                                        config.i18n.moduleList
                                                            .student.progression
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!isNaN(
                                            diagnostics[key].averageScore
                                        ) ? (
                                            <div className="bar success-rate-active">
                                                <div className="text-content">
                                                    {
                                                        config.i18n.moduleList
                                                            .student.successRate
                                                    }
                                                </div>
                                                <span>{`${diagnostics[key].averageScore}%`}</span>
                                            </div>
                                        ) : (
                                            <div className="bar">
                                                <div className="text-content">
                                                    {
                                                        config.i18n.moduleList
                                                            .student.successRate
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-modal">
                                    <button
                                        data-key={key}
                                        onClick={() => {
                                            history.push(
                                                "/player/bandit-manchot/" + key
                                            );
                                        }}
                                        className="btn-modal"
                                    >
                                        {!isNaN(diagnostics[key].averageScore)
                                            ? config.i18n.moduleList.student
                                                  .continue
                                            : config.i18n.moduleList.student
                                                  .start}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {
                    config.logos.avatarStudentBottomRight ? 
                    <div className="avatarBottom">
                        <img src={config.logos.avatarStudentBottomRight} />
                    </div>
                    : null
                }

            </div>
        </>
    );
}

export default ModuleListStudent;
