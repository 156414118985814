import React from "react";

import "./Group.scss";

interface GroupProps {
    groupeName: string;
    students: {name: string, id: string}[];
    selected: boolean;
    onSelectGroup: () => void;
    selectedStudentId?: string;
    setSelectedStudentId: (index?: string) => void;
    groupIndex: number; // TEMP, only for colors
}

const Group: React.FC<GroupProps> = ({
    groupeName,
    students,
    onSelectGroup,
    selected,
    setSelectedStudentId,
    selectedStudentId,
    groupIndex,
}) => {
    if (!selected)
        return (
            <div className={`group group-${groupIndex + 1}`} onClick={onSelectGroup}>
                <h3 className="group-name">{groupeName}</h3>
                {students.map((student, i) => (
                        student.name !== "undefined" &&
                            <div key={`student-name-${i}`} className="student-name">
                                {student.name}
                            </div>
                ))}
            </div>
        );

    return (
        <div className="group-detail">
            <h3 className={`title group-${groupIndex + 1}`} onClick={onSelectGroup}>
                {groupeName}
            </h3>
            {students.map((student, index) => (
                 student.name !== "undefined" &&
                    <div
                        key={"student" + index}
                        className={`student group-${groupIndex + 1}-student ${student.id === selectedStudentId? "selected" : ""}`}
                        onClick={() => {
                            if (student.id === selectedStudentId) setSelectedStudentId(undefined);
                            else setSelectedStudentId(student.id);
                        }}
                    >
                        { student.name }
                    </div>
            ))}
        </div>
    );
};

export default Group;
