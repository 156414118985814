import React from "react";
import styled from "styled-components/macro";

interface Props extends React.AllHTMLAttributes<HTMLButtonElement> {
    Icon?: React.ComponentType;
    materialIcon?: string;
    materialIconColor?: string;
}

/**
 * Generic action button component for the dashboard.
 *
 * If the Icon prop is set, it overrides the materialIcon prop.
 */
const DashboardActionButton: React.FC<Props> = ({
    Icon,
    materialIcon,
    materialIconColor = "var(--color-primary)",
    children,
    ...delegated
}) => {
    return (
        /* @ts-ignore: TS isn't happy with variable setting in style prop */
        <Wrapper {...delegated} style={{ "--icon-color": materialIconColor }}>
            {Icon ? (
                <Icon />
            ) : typeof materialIcon !== "undefined" ? (
                <span className="material-icons">{materialIcon}</span>
            ) : null}

            <Label>{children}</Label>

            {(Icon || typeof materialIcon !== "undefined") && <Placeholder />}
        </Wrapper>
    );
};

const Wrapper = styled.button`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    display: block;
    width: 100%;
    min-height: 60px;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 20px;
    background: #f2f6fb;
    border: 1px solid var(--color-primary);

    & > span:first-child.material-icons {
        flex: 1 1 0;
        font-size: 2.4em;
        color: var(--icon-color);
        margin-left: -10px; // Undo button padding
    }

    &:disabled {
        pointer-events: none;
        
        > span:first-child.material-icons {
            color: rgba(0, 0, 0, 0.5);
        }
    }
    &.in-progress-module-handler{
        border:none;
        background-color:#F9F9F9;
        box-shadow:none;
    }
`;

const Label = styled.span`
    display: block;
    width: 170px;
    margin: 0 auto;
    font-size: 0.9rem;
    text-align: center;
    padding: 0 5px;
    font-size: 1em;
`;

// This placeholder allows the centering of the label in case there is an icon
const Placeholder = styled.span`
    flex: 1 1 0;
    margin-right: -10px; // Undo button padding
`;

export default DashboardActionButton;
