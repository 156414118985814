import React from "react";
import Portal from "@reach/portal";
import { Properties } from "csstype";
import { useTooltip, TooltipPopup, Position } from "@reach/tooltip";

// This component was taken and adapted from https://reach.tech/tooltip/
// section "Triangle pointers and custom styles"

export type Direction = "up" | "down" | "left" | "right";

const centered: { [K in Direction]: Position } = {
    left: (triggerRect, tooltipRect) => {
        if (!triggerRect || !tooltipRect)
            throw new Error("Triangle tooltip error");

        const triggerCenter = triggerRect.top + triggerRect.height / 2;
        const top = triggerCenter - tooltipRect.height / 2;
        return {
            top: Math.max(2, top) + window.scrollY,
            left: triggerRect.right + 8 + window.scrollX,
        };
    },

    right: (triggerRect, tooltipRect) => {
        if (!triggerRect || !tooltipRect)
            throw new Error("Triangle tooltip error");

        const triggerCenter = triggerRect.top + triggerRect.height / 2;
        const top = triggerCenter - tooltipRect.height / 2;
        return {
            top: Math.max(2, top) + window.scrollY,
            left: triggerRect.left - 8 - tooltipRect.width + window.scrollX,
        };
    },

    up: (triggerRect, tooltipRect) => {
        if (!triggerRect || !tooltipRect)
            throw new Error("Triangle tooltip error");

        const triggerCenter = triggerRect.left + triggerRect.width / 2;
        const left = triggerCenter - tooltipRect.width / 2;
        return {
            left: Math.max(2, left) + window.scrollX,
            top: triggerRect.bottom + 8 + window.scrollY,
        };
    },

    down: (triggerRect, tooltipRect) => {
        if (!triggerRect || !tooltipRect)
            throw new Error("Triangle tooltip error");

        const triggerCenter = triggerRect.left + triggerRect.width / 2;
        const left = triggerCenter - tooltipRect.width / 2;
        return {
            left: Math.max(2, left) + window.scrollY,
            top: triggerRect.top - tooltipRect.height - 8 + window.scrollX,
        };
    },
};

const triangleStyle: {
    [K in Direction]: (
        triggerRect: DOMRect | null | undefined,
        color: string
    ) => React.CSSProperties;
} = {
    left: (triggerRect, color) => {
        return {
            position: "absolute",
            top:
                (triggerRect &&
                    triggerRect.top - 10 + triggerRect.height / 2) ??
                undefined,
            left:
                (triggerRect && triggerRect.right + window.scrollY) ??
                undefined,
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: "10px solid " + color,
        };
    },

    right: (triggerRect, color) => {
        return {
            position: "absolute",
            top:
                (triggerRect &&
                    triggerRect.top - 10 + triggerRect.height / 2) ??
                undefined,
            left:
                (triggerRect && triggerRect.left - 10 + window.scrollY) ??
                undefined,
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderLeft: "10px solid " + color,
        };
    },

    up: (triggerRect, color) => {
        return {
            position: "absolute",
            left:
                (triggerRect &&
                    triggerRect.left - 10 + triggerRect.height / 2) ??
                undefined,
            top:
                (triggerRect && triggerRect.bottom + window.scrollY) ??
                undefined,
            width: 0,
            height: 0,
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            borderBottom: "10px solid " + color,
        };
    },

    down: (triggerRect, color) => {
        return {
            position: "absolute",
            left:
                (triggerRect &&
                    triggerRect.left - 10 + triggerRect.height / 2) ??
                undefined,
            top:
                (triggerRect && triggerRect.top - 10 + window.scrollY) ??
                undefined,
            width: 0,
            height: 0,
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent",
            borderTop: "10px solid " + color,
        };
    },
};


const TriangleTooltip: React.FC<{
    label: React.ReactNode;
    "aria-label"?: string;
    pointing?: Direction;
    style: Properties<string | number, string & {}>;
}> = ({ children, label, "aria-label": ariaLabel, style, pointing = "left" }) => {
    // get the props from useTooltip
    const [trigger, tooltip] = useTooltip();
    // destructure off what we need to position the triangle
    const { isVisible, triggerRect } = tooltip;

    return (
        <>
            {
                /* @ts-ignore */
                React.cloneElement(children, trigger)
            }

            {isVisible && (
                // The Triangle. We position it relative to the trigger, not the popup
                // so that collisions don't have a triangle pointing off to nowhere.
                // Using a Portal may seem a little extreme, but we can keep the
                // positioning logic simpler here instead of needing to consider
                // the popup's position relative to the trigger and collisions
                <Portal>
                    <div
                        style={triangleStyle[pointing](
                            triggerRect,
                            "" + (style.backgroundColor || style.background || "black")
                        )}
                    />
                </Portal>
            )}

            <TooltipPopup
                {...tooltip}
                label={label}
                aria-label={ariaLabel}
                style={style}
                position={centered[pointing]}
            />
        </>
    );
};

export default TriangleTooltip;
