import { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
    ComingNext,
    Exercise,
    ExerciseResult,
    PlaylistExecutionStage,
    PlaylistManager,
    Hierarchy,
    HierarchyIds,
    Playlist,
    ActivityShell,
} from "@evidenceb/gameplay-interfaces";
import {
    BanditManchotWhisperer,
    HistoryItem,
    History,
} from "@evidenceb/bandit-manchot";
import {
    BanditManchotInstanceNotFoundError,
    PlayerProgressionError,
} from "../errors";
import { dataStore } from "../contexts/DataContext";
import { sessionStore } from "../contexts/SessionContext";
import {
    getHierarchyFromHierarchyId,
    getRandomExercise,
} from "../utils/dataRetrieval";
import * as trackingUtils from "../utils/tracking";
import { getContext } from "../utils/sentry";
import { configStore } from "../contexts/ConfigContext";
import useStatements from "./useStatements";

/**
 * This is the Bandit Manchot playlist manager. It uses the Bandit Manchot AI
 * to determine which are the exercises that are played. A Bandot Manchot user
 * should:
 * - not be able to navigate freely between exercises
 * - be assigned their next exercise depending on their history by the Bandit
 *   Manchot AI
 * - be made aware whether they are in the initial test or not
 * - receive incentive messages periodically
 * and, in the context of the chatbot shell:
 * - start with a clean conversation (without their past responses) when
 *   picking up an already started module
 */
// TODO:
// - Add incentive messages

interface BMPlaylistManager extends PlaylistManager {
    playlist: Playlist & { isInitialTest: boolean };
    clearHistory: () => void;
    initialized: true;
}

const useBanditManchotPlaylistManager = (
    moduleId: string
): BMPlaylistManager | { initialized: false } => {
    const [initialized, setInitialized] = useState<boolean>(false);

    const { data } = useContext(dataStore);
    const {
        session: { banditManchot, initialHistory, flags },
        setSession,
    } = useContext(sessionStore);
    if (!banditManchot[moduleId])
        throw new BanditManchotInstanceNotFoundError();
    const { sendResult, getResultStatements } = useStatements();

    // History of exercises retrieved from the LRS and exercises completed during the session
    const [history, setHistory] = useState<HistoryItem[]>();
    // List of exercise hierarchy given by the bandit manchot for the current session
    const [hierarchyList, setHierarchyList] = useState<Hierarchy[]>();
    // Index of the current exercise in the hierarchy list
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    // Timestamp of the begining of the current exercise
    const [
        currentExerciseStartTS,
        setCurrentExerciseStartTS,
    ] = useState<number>();
    // List of exercise results for the current session
    // TODO: Refactor exerciseResult and history
    const [exerciseResults, setExerciseResults] = useState<
        ExerciseResult<any>[]
    >([]);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentTry, setCurrentTry] = useState<number>(1);
    // Nature of the current step
    const [
        currentExecutionStage,
        setCurrentExecutionStage,
    ] = useState<PlaylistExecutionStage>(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    // Nature of the next step after the current exercise
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );

    // Initialize Bandit manchot and playlist
    useEffect(() => {
        setInitialized(false);

        const initializeBanditManchotHistory = (
            history: HistoryItem[]
        ): HierarchyIds | undefined => {
            let nextHierarchyIds: HierarchyIds | undefined = undefined;
            try {
                nextHierarchyIds = BanditManchotWhisperer.loadHistoryAndGetNextHierarchyIds(
                    banditManchot[moduleId].instance,
                    history
                );
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: {
                            ...banditManchot,
                            [moduleId]: {
                                ...banditManchot[moduleId],
                                historyLoaded: true,
                            },
                        },
                    };
                });
            } catch (err) {
                Sentry.captureException(
                    err,
                    getContext({ "Bandit Manchot": { moduleId, history } })
                );
            }
            return nextHierarchyIds;
        };

        const initializePlaylist = (nextHierarchyIds: HierarchyIds): void => {
            setHierarchyList([
                {
                    ...getHierarchyFromHierarchyId(nextHierarchyIds, data),
                    isInitialTest: nextHierarchyIds.isInitialTest,
                },
            ]);
            setCurrentExerciseStartTS(Date.now());
        };

        const initializeManager = (initialHistory: HistoryItem[]) => {
            setHistory(initialHistory);
            let nextHierarchyIds: HierarchyIds | undefined = undefined;
            if (!banditManchot[moduleId].historyLoaded) {
                nextHierarchyIds = initializeBanditManchotHistory(
                    initialHistory
                );
            } else {
                try {
                    nextHierarchyIds = BanditManchotWhisperer.getNextHierarchyId(
                        banditManchot[moduleId].instance
                    );
                } catch (err) {
                    Sentry.captureException(
                        err,
                        getContext({"Bandit Manchot": { moduleId, history: initialHistory }})
                    );
                }
            }
            initializePlaylist(
                nextHierarchyIds || {
                    ...getRandomExercise(data, moduleId),
                    isInitialTest: false,
                }
            );
        };

        if (initialHistory && initialHistory[moduleId]) {
            setHistory(initialHistory[moduleId]);
            initializeManager(initialHistory[moduleId]);
            setInitialized(true);
        } else {
            // TODO: Error handling strategy
            // For now, if a call fails, the user is given an empty history and a random exericise, but is not notified
            getResultStatements()
                .then((statements) => {
                    const initialHistory: History = {};
                    Object.keys(banditManchot).forEach((moduleId) => {
                        initialHistory[
                            moduleId
                        ] = trackingUtils.statementsToHistory(
                            trackingUtils.filterStatementsByModule(
                                statements,
                                moduleId
                            )
                        );
                    });
                    setSession((curr) => {
                        return { ...curr, initialHistory };
                    });
                    initializeManager(initialHistory[moduleId]);
                    setInitialized(true);
                })
                .catch((err) => {
                    Sentry.captureException(err, getContext({"Bandit Manchot": { moduleId }}));
                    initializeManager([]);
                    setInitialized(true);
                });
        }
    }, [flags.useHistoryFrom]);

    // Reinit current exercise related information when current exercise
    // changes or another try starts
    useEffect(() => {
        setComingNext(undefined);
        setCurrentExerciseResult(undefined);
        setCurrentExecutionStage(PlaylistExecutionStage.PlayingCurrentExercise);
    }, [currentExerciseIndex, currentTry]);

    // Reinit current try when current exercise changes
    useEffect(() => {
        setCurrentTry(1);
        setCurrentExerciseStartTS(Date.now());
    }, [currentExerciseIndex]);

    if (!initialized) return { initialized };

    return {
        initialized,

        playlist: {
            module: hierarchyList![currentExerciseIndex].module,
            objective: hierarchyList![currentExerciseIndex].objective,
            activity: hierarchyList![currentExerciseIndex].activity,
            exercises: hierarchyList!.map((hierarchy) => hierarchy.exercise),
            currentExercise: hierarchyList![currentExerciseIndex].exercise,
            currentTry,
            currentExerciseResult,
            isInitialTest: hierarchyList![currentExerciseIndex].isInitialTest,
            comingNext: comingNext,
            exerciseResults: exerciseResults,
            currentExecutionStage,
        },

        recordCurrentExerciseResult: (partialExerciseResult) => {
            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                exerciseId: hierarchyList![currentExerciseIndex].exercise.id,
                try: currentTry,
                feedback: hierarchyList![currentExerciseIndex].exercise
                    .feedback[currentTry - 1][
                    partialExerciseResult.correct ? "correct" : "incorrect"
                ],
                activityId: hierarchyList![currentExerciseIndex].activity!.id,
            };
            setCurrentExerciseResult(exerciseResult);
            setExerciseResults((curr) => [...curr, exerciseResult]);

            const whatsComingNext = getWhatsComingNext(
                currentExerciseIndex,
                hierarchyList!.map((hierarchy) => hierarchy.exercise),
                currentTry,
                exerciseResult
            );
            setComingNext(whatsComingNext);

            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            if (whatsComingNext !== "retry") {
                // Update history
                const historyItem = {
                    exerciseId: hierarchyList![currentExerciseIndex].exercise
                        .id,
                    activityId: hierarchyList![currentExerciseIndex].activity
                        .id,
                    objectiveId: hierarchyList![currentExerciseIndex].objective
                        .id,
                    score: partialExerciseResult.score
                        ? partialExerciseResult.score / currentTry
                        : partialExerciseResult.correct
                        ? 1 / currentTry
                        : 0,
                };
                sendResult(
                    {
                        ...historyItem,
                        moduleId,
                        isInitialTest: hierarchyList![currentExerciseIndex]
                            .isInitialTest,
                        answer: partialExerciseResult.answer,
                        duration: Date.now() - currentExerciseStartTS!,
                        success: partialExerciseResult.correct,
                    }
                );
                setHistory((curr) => [...curr!, historyItem]);

                // Get next exercise
                let newHierarchyIds: HierarchyIds;
                try {
                    newHierarchyIds = BanditManchotWhisperer.updateHistoryAndGetNextHierarchyIds(
                        banditManchot[moduleId].instance,
                        historyItem
                    );
                } catch (err) {
                    Sentry.captureException(
                        err,
                        getContext({"Bandit Manchot": { moduleId, history }})
                    );
                    newHierarchyIds = {
                        ...getRandomExercise(data, moduleId),
                        isInitialTest: false,
                    };
                }
                setHierarchyList((curr) => [
                    ...curr!,
                    {
                        ...getHierarchyFromHierarchyId(newHierarchyIds, data),
                        isInitialTest: newHierarchyIds.isInitialTest,
                    },
                ]);

                // Show end of initial test message when needed
                if (
                    hierarchyList![currentExerciseIndex].isInitialTest &&
                    !newHierarchyIds.isInitialTest
                ) {
                    if (
                        hierarchyList![currentExerciseIndex].activity.shell ===
                        ActivityShell.Chatbot
                    )
                        // Timeout to show message after chatbot thinking animation delay
                        setTimeout(() => {
                            setCurrentExecutionStage(
                                PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                            );
                        }, 1000);
                    else
                        setCurrentExecutionStage(
                            PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                        );
                }
            }
        },

        goToNextExercise: () => {
            if (!comingNext)
                throw new PlayerProgressionError(
                    "goToNextExercise called before validating exercise"
                );

            if (comingNext === "retry") setCurrentTry(currentTry + 1);
            else setCurrentExerciseIndex(currentExerciseIndex + 1);
        },

        goToExercise: () => {
            throw new PlayerProgressionError(
                "Students cannot navigate to a specific exercise"
            );
        },

        clearHistory: () => {
            setExerciseResults([]);
        },
    };
};

const getWhatsComingNext = (
    currentExerciseIndex: number,
    exerciseList: Exercise<any, any>[],
    currentTry: number,
    exerciseResult: ExerciseResult<any>
): PlaylistManager["playlist"]["comingNext"] => {
    if (!exerciseResult) return undefined;

    // For backwards compatibility
    const numberOfTries =
        exerciseList[currentExerciseIndex].executionOptions?.numberOfTries ||
        exerciseList[currentExerciseIndex]?.numberOfTries;

    if (!exerciseResult.correct && currentTry < numberOfTries!) return "retry";
    return "nextExercise";
};

export default useBanditManchotPlaylistManager;
