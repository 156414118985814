import React, { useContext } from "react";
import { configStore } from "../../../contexts/ConfigContext";

import "./GroupCharacteristics.scss";
import {
    ClusterInfosClustering,
    InfosEleves,
    InfosVariables,
} from "../../../interfaces/Dashboard";

interface GroupCharacteristicsProps {
    groupIndex: number; // TEMP for color
    selected: boolean;
    onSelectGroup: () => void;
    selectedStudentIndex?: number;
    cluster: ClusterInfosClustering;
    variables: (InfosVariables & { name: string })[];
    selectedStudent?: InfosEleves;
}

const GroupCharacteristics: React.FC<GroupCharacteristicsProps> = ({
    cluster,
    variables,
    groupIndex,
    selected,
    onSelectGroup,
    selectedStudent,
}) => {
    const { config } = useContext(configStore);

    return (
        <div className="group-characteristics" onClick={onSelectGroup}>
            <div className="header">
                <div className={`circle --group-${groupIndex + 1}`} />
                <div className="title">{cluster.name}</div>
            </div>
            <div
                className={`characteristic characteristic-border-${
                    groupIndex + 1
                } ${selected ? "selected" : ""} ${typeof selectedStudent !== "undefined" ? "selected-student" : ""}`}
            >
                {!selected ? (
                    <div style={{ marginLeft: "10px" }}>
                        {`${getNumberOfVariables(cluster, variables)} ${
                            getNumberOfVariables(cluster, variables) > 1
                                ? config.i18n.dashboard?.clustering
                                      .characteristics
                                : config.i18n.dashboard?.clustering
                                      .characteristic
                        }`}
                    </div>
                ) : (
                    variables.map(
                        (variable, key) =>
                            /* @ts-ignore: Checking for key existence should be fine*/
                            typeof cluster[variable.name] !== "undefined" && (
                                <CharacteristicDetails
                                    key={'CharacteristicDetails' + key}
                                    groupIndex={groupIndex}
                                    variable={variable}
                                    /* @ts-ignore variable.name should be one of var1 var2 or var3 and there should be a value provided for the cluster */
                                    clusterValue={cluster[variable.name]}
                                    selectedStudentValue={
                                        selectedStudent
                                            ? /* @ts-ignore variable.name should be one of var1 var2 or var3 and there should be a value provided for the student */
                                              selectedStudent[variable.name]
                                            : undefined
                                    }
                                />
                            )
                    )
                )}
            </div>
        </div>
    );
};

const CharacteristicDetails: React.FC<{
    groupIndex: number;
    variable: InfosVariables;
    clusterValue: string;
    selectedStudentValue?: string;
}> = ({ groupIndex, variable, selectedStudentValue, clusterValue }) => {
    return (<>
            <div
                className={`detailed-characteristic-title --group-${
                    groupIndex + 1
                }`}
            >
                {variable.name === "var1" ? "Score moyen pour tous les exercices réalisés" : variable.description}
            </div>
              
            <div
                className={`duration --group-${groupIndex + 1}`}
            >{`${clusterValue} ${variable.unit} ${variable.name === "var1" ? "/1" : ""}`}</div>

            {selectedStudentValue && (
                <div
                    className="characteristic-details__student-value"
                >{`${selectedStudentValue} ${variable.unit} ${variable.name === "var1" ? "/1" : ""}`}</div>
            )}
    </>);
};

const getNumberOfVariables = (
    cluster: ClusterInfosClustering,
    variables: (InfosVariables & { name: string })[]
): number => {
    return variables.filter(
        /* @ts-ignore: Checking for key existence should be fine*/
        (variable) => typeof cluster[variable.name] !== "undefined"
    ).length;
};

export default GroupCharacteristics;
