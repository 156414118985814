import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
//
import { colors } from "../utils";
import { configStore } from "../../../../contexts/ConfigContext";
import DemoBanner from "../../../../components/DemoBanner/DemoBanner";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import { getModuleById } from "../../../../utils/dataRetrieval";
import { dataStore } from "../../../../contexts/DataContext";
import {
    ClusterInfosClustering,
    InfosVariables,
    ModuleCluster,
} from "../../../../interfaces/Dashboard";
import BackAndForth from "../../../../components/BackAndForth/BackAndForth";
import { Data } from "../../../../interfaces/Data";

const VARIABLES: ["var1", "var2", "var3"] = ["var1", "var2", "var3"];

export default function DetailsCard({
    studentId,
    classroomId,
}: {
    studentId: string;
    classroomId: string;
}) {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    const {
        dashboard: { clustering },
    } = useContext(dashboardStore);
    // Take only current classroom and remove modules that have errors in clustering or for which the student has no cluster
    let _clustering: { [moduleId: string]: ModuleCluster } = {};
    if (clustering && clustering[classroomId]) {
        Object.keys(clustering![classroomId]).forEach((moduleId) => {
            if (
                typeof clustering![classroomId][moduleId].error !==
                    "undefined" ||
                !findStudentCluster(
                    studentId,
                    (clustering![classroomId][moduleId] as ModuleCluster)
                        .infosClustering.clusters
                )
            )
                return;

            _clustering[moduleId] = clustering![classroomId][
                moduleId
            ] as ModuleCluster;
        });
    }

    const modulesOrder: string[] =
        Object.keys(_clustering).length > 0
            ? Object.keys(_clustering)
                  .map((moduleId) => {
                      return {
                          moduleId,
                          title: getModuleById(moduleId, data).title.short!,
                      };
                  })
                  .sort((a, b) => {
                      if (a.title < b.title) {
                          return -1;
                      }
                      if (a.title > b.title) {
                          return 1;
                      }
                      return 0;
                  })
                  .map((item) => item.moduleId)
            : [];

    const [currentModuleId, setCurrentModuleId] = useState<string | undefined>(
        modulesOrder.length > 0 ? modulesOrder[0] : undefined
    );
    const [studentCluster, setStudentCluster] = useState<
        ClusterInfosClustering | undefined
    >(
        currentModuleId
            ? findStudentCluster(
                  studentId,
                  _clustering[currentModuleId].infosClustering.clusters
              )!
            : undefined
    );

    const getModuleName = (moduleId: string, data: Data): string => {
        const module = getModuleById(moduleId, data);
        return `${module.title.short!}. ${module.title.long}`;
    }

    // If current module id changes, update studentCluster
    useEffect(() => {
        if (!currentModuleId) return;

        setStudentCluster(
            findStudentCluster(
                studentId,
                _clustering[currentModuleId].infosClustering.clusters
            )!
        );
    }, [currentModuleId]);

    if (!currentModuleId || !studentCluster) return <></>;

    return (
        <div className="card small">
            <h2 className="card-title">
                {config.i18n.dashboard?.common.groups}
            </h2>
            <Card>
                {config.features.demoMode?.displayIn.clustering ? (
                    <DemoBanner
                        position="custom"
                        message={config.features.demoMode.message.clustering}
                    />
                ) : null}

                <BackAndForth<string>
                    valuesList={modulesOrder}
                    onValueChange={(value) => {
                        setCurrentModuleId(value);
                    }}
                    currentValue={currentModuleId}
                    infinite
                    instructions={{
                        back: config.i18n.dashboard!.studentDetails
                            .goToPreviousModule,
                        forth: config.i18n.dashboard!.studentDetails
                            .goToNextModule,
                    }}
                >
                    <ModuleTitle>
                        {getModuleName(currentModuleId, data)}
                    </ModuleTitle>
                </BackAndForth>

                <GroupName>{studentCluster.name}</GroupName>

                <VariablesWrapper>
                    {VARIABLES.map((variable) => (
                        <>
                            <VariableDescription>
                                {
                                    getVariable(
                                        variable,
                                        _clustering[currentModuleId]
                                    ).description
                                }
                            </VariableDescription>
                            <VariableStudentValue>
                                {getStudentValue(
                                    studentId,
                                    variable,
                                    _clustering[currentModuleId]
                                )}
                            </VariableStudentValue>
                        </>
                    ))}
                </VariablesWrapper>
            </Card>
        </div>
    );
}

const findStudentCluster = (
    studentId: string,
    clusters: { [clusterName: string]: ClusterInfosClustering }[]
): ClusterInfosClustering | undefined => {
    const _clusters = clusters
        .map((clustersObj) =>
            Object.keys(clustersObj).map(
                (clusterName) => clustersObj[clusterName]
            )
        )
        .flat();
    return _clusters.find((cluster) => cluster.eleves.includes(studentId));
};

const getVariable = (
    variable: string,
    clustering: ModuleCluster
): InfosVariables & { variableName: "var1" | "var2" | "var3" } => {
    const variables = clustering.infosVariables
        .map((variableObj) =>
            Object.keys(variableObj).map((variableName) => {
                return { ...variableObj[variableName], variableName };
            })
        )
        .flat();
        let v = variables.find(
            (item) => item.variableName === variable
        )! as InfosVariables & { variableName: "var1" | "var2" | "var3" }
        if(variable === "var1"){
            v.description = "Score moyen pour tous les exercices réalisés"
        }
    return v;
};

const getStudentValue = (
    studentId: string,
    variableName: "var1" | "var2" | "var3",
    clustering: ModuleCluster
): string => {
    const students = clustering.infosEleves
        .map((studentObj) =>
            Object.keys(studentObj).map((studentId) => studentObj[studentId])
        )
        .flat();
    const student = students.find((student) => student.id === studentId)!;
    const variable = getVariable(variableName, clustering);
    return `${ student[variableName]} ${variable.unit} ${variableName === "var1" ? "/1" : ""}`;
};

const Card = styled.div`
    background: ${colors.lightGrey};
    padding: 16px 24px;
    border-radius: 8px;
    width: calc(100% - 48px);
    height: calc(100% - 68px);
`;

const GroupName = styled.h4`
    text-align: center;
    font-size: 1.5em;
    margin: 16px 0;
    text-transform: capitalize;
`;

const VariablesWrapper = styled.div`
    padding: 16px;
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: 1fr max-content;
`;

const VariableDescription = styled.div`
    font-weight: 700;
    flex: 1;
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-right: 1px solid ${colors.lightGrey};
    border-radius: 16px 0 0 16px;
`;

const VariableStudentValue = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 0 16px 16px 0;
`;

const ModuleTitle = styled.span`
    font-size: 1.5rem;
`;