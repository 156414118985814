import React, {useState, Dispatch, SetStateAction, useEffect } from "react";

//Reach UI (accessibility UI)
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
import "@reach/dialog/styles.css";

import "./Modal.scss";

type ModalProps = {
    title: string;
    showDialog: boolean;
    setShowDialog: Dispatch<SetStateAction<boolean>>;
};

const Modal: React.FC<ModalProps> = ({ title, showDialog, setShowDialog, children }) => {
    const close = () => setShowDialog(false);
    return (
        <Dialog isOpen={showDialog} onDismiss={close} className="modal-container" aria-labelledby={title}>
            <div className="modal">
                <header>
                    <span>{title}</span>
                    <button className="close-button" onClick={close}>
                        <VisuallyHidden>Close</VisuallyHidden>
                        <span aria-hidden>
                            <span className="material-icons">close</span>
                        </span>
                    </button>
                </header>
                <div className="content">
                    {children}
                </div>
            </div>
        </Dialog>
    );
};

export default Modal;
