import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Config as AiConfig } from "@evidenceb/bandit-manchot";
import {DashboardI18n} from "./i18n";
export enum Pagetype {
    HOME = "HOME",
    MODULELIST = "MODULELIST",
    DASHBOARD = "DASHBOARD",
    LOGOUT = "LOGOUT",
    CONTENTPAGE = "CONTENTPAGE",
}

export interface Title {
    title: string;
}

export interface UrlDisplay{
    mode: "DEFAULT" | "USER_BASED";
    url: string[] | string;
    label: string[] | string;
}

export interface Page {
    display: UrlDisplay;
    type: Pagetype;
    inTopNav: boolean;
    inFooter: boolean;
    authorized?: string[];
}

export interface MicroServices{
    match: string;
    endpoints: {
        auth?: string;
        content: string;
        analytics?: string;
        users?: string;
        statements?: string;
        misc?: string;
    }
}

export interface Config {
    versioning: number;
    declinaison: string;
    client_name: string;
    activate_contentPage: boolean;
    debug?:{
        exerciseIdentifier: boolean;
    }
    features:{
        teacherDashboard: boolean;
        classManagement?: boolean;
        tracking?: boolean;
        clustering?:boolean;
        demoMode?: {
            displayIn:{
                classList: boolean;
                classDetails: boolean;
                progressionOverview: boolean;
                studentProgression: boolean;
                studentDetails: boolean;
                clustering: boolean;
            };
            message: {
                global: HtmlString;
                studentProgression: HtmlString;
                clustering:HtmlString;
            };
        },
        /**
         * Controls whether or not the class code should be shown in the
         * dashboard.
         * @default true
         */
        classCode?: boolean;
    },
    pages: Page[];
    msConfigs?: MicroServices;
    auth: {
        mode: "REGISTER" | "REGISTER_NAMEONLY" | "DIRECT_ACCESS"
        rightImage: string;
        displaySignIn: boolean; // TEMP
        provider?:string;
    },
    i18n: {
        auth:{
            student:{
                registerClass: {
                    title: string;
                    text: HtmlString;
                    classCode:{
                        info: HtmlString;
                    }
                },
                signIn:{
                    text: HtmlString;
                }
            },
            teacher:{
                signIn:{
                    title:string;
                    text: HtmlString;
                }
            },
            form:{
                classCode: string;
                error:{
                    codeClassError:HtmlString;
                    authError: HtmlString;
                    name:string;
                    firstname:string;
                },
                button:{
                    start:string;
                    next:string;
                }
                firstname:string;
                name:string;
            }
        },
        hierarchy: {
            module: {
                full: string;
                short: string;
            };
            objective: {
                full: string;
                short: string;
            };
            activity: {
                full: string;
                short: string;
            };
            exercise: {
                full: string;
                short: string;
            };
        };
        moduleList: {
            info: string;
            title: string;
            student: {
                message: {
                    default: HtmlString;
                    visited: HtmlString;
                };
                progression: string;
                successRate: string;
                start: string;
                continue: string;
            };
        };
        endPlaylist: {
            congratulations: string;
            completed: string;
            result: string;
            exerciseCorrect: string;
            exerciseIncorrect: string;
            moduleList: string;
            nextModule: string;
            dashboard: string;
        };
        exerciseShell: {
            close: string;
            feedback: {
                nextExercise: string;
                nextActivity: string;
                endPlaylist: string;
                retry: string;
            };
            tryAgain: HtmlString;
            initialTestMessages: {
                start: HtmlString;
                end: HtmlString;
                btnStart: string;
                btnEnd: string;
            };
        };
        dashboard?:DashboardI18n;
        misc: {
            logout: string;
            logoutUrl: {
                provider:string;
                url:string;
            }[];
            contact: string;
            developedBy: string;
            colon: string;
        };
        chatbot: {
            welcomeMessages: HtmlString[];
            start: HtmlString;
            // TODO: Remove and use the one in exerciseShell
            initialTestMessages: {
                start: HtmlString;
                end: HtmlString;
            };
        };
        gameplays: {
            generic: {
                btnValidate: string;
            };
            [gameplayType: string]: {
                [key: string]: string;
            };
        };
        drawer: {
            targetedDifficulties: string;
        };
    };
    logos: {
        avatar: string;
        avatarStudentBottomRight?: string,
        demo?: string;
        header_client: string;
        endPlaylistIcon: string;
        footer_evidenceb: string;
        footer_client: string;
    };
    contact: string;
    ai?: AiConfig;
}
