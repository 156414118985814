import React, { useContext, useState } from "react";
import DashboardActionButton from "../../../../../components/DashboardActionButton/DashboardActionButton";
import Loader from "../../../../../components/Loader/Loader";
import { configStore } from "../../../../../contexts/ConfigContext";
import * as localStorage from "../../../../../utils/localStorage";

/**
 * Component that displays a button for module activation/deactivation and makes
 * the appropriate call to the backend
 *
 * ADMATH ONLY
 */
const ModuleDeactivation: React.FC<{
    active: boolean;
    toggleStatus: () => void;
    classroomId: string;
    moduleId: string;
}> = ({ active, toggleStatus, classroomId, moduleId }) => {
    const { config } = useContext(configStore);
    /** True when the button was clicked and the call is being made */
    const [processing, setProcessing] = useState<boolean>(false);

    const token = localStorage.getItem<string>(localStorage.Key.TOKEN);
    const version = localStorage.getItem<string>(localStorage.Key.VERSION);

    const toggleRequest = () => {
        const headers = new Headers({
            version: localStorage.getItem<string>(localStorage.Key.VERSION)!,
            authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )!}`,
        });
        return fetch(
            `${config.msConfigs!.endpoints
                .misc!}/toggle_module_status/${classroomId}/${moduleId}`,
            {
                method: "POST",
                headers,
            }
        );
    };

    // [TODO] Error handling
    if (!token || !version) return <Loader />;

    return (
        <DashboardActionButton
            disabled={processing}
            materialIcon="power_settings_new"
            onClick={async () => {
                setProcessing(true);
                if (!active) {
                    try {
                        await toggleRequest();
                        toggleStatus();
                    } catch {
                        // [TODO] Error handling
                    }
                } else {
                    toggleStatus();
                    try {
                        await toggleRequest();
                    } catch {
                        // Reactivate module in UI if the call failed
                        toggleStatus();
                    }
                }
                setProcessing(false);
            }}
        >
            {active ? "Désactiver " : "Activer "}
            le module
        </DashboardActionButton>
    );
};

export default ModuleDeactivation;
