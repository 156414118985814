import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import ModuleListItem from "../../components/ModuleListItem/ModuleListItem";
import "./ModuleList.scss";

import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";

import { Title } from "../../interfaces/Config";
import { getObjectiveById } from "../../utils/dataRetrieval";

function ModuleList({ title }: Title) {
    console.log("ML");
    
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div className="modules-list">
                <h1>{config.i18n.moduleList.title}</h1>
                {config.i18n.moduleList.info !== "" ? (
                    <div className="info">
                        <p>{config.i18n.moduleList.info}</p>
                    </div>
                ) : null}

                {data.modules.map((module, i) => (
                    <ModuleListItem
                        key={"item" + i}
                        id={module.id}
                        title={{long: module.title.long, short: module.title.short}}
                        description={module.description}
                        objectives={module.objectiveIds.map((objectiveId) =>
                            getObjectiveById(objectiveId, data, false)
                        )}
                    />
                ))}
            </div>
        </>
    );
}

export default ModuleList;
