import React, { useContext, useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from "@reach/accordion";
import Modal from "../../../../components/Modal/Modal";
import { getModuleById } from "../../../../utils/dataRetrieval";
import { dataStore } from "../../../../contexts/DataContext";

/**
 * Modal for the start path feature
 *
 * ADMATH ONLY
 */
const PathStartModal: React.FC<{
    students: { lastname: string; firstname: string, moduleId:string }[];
    onStartPath: () => void;
    onModalClose: () => void;
}> = ({ students, onStartPath, onModalClose }) => {

    const [showModal, setShowModal] = useState<boolean>(students.length > 0);
    const {data} = useContext(dataStore)
    // Filter unique students
    const uniqueStudents = students.reduce((uniqueStudents, currentStudent) => {
        if (
            uniqueStudents.find(
                (student) =>
                    student.firstname === currentStudent.firstname &&
                    student.lastname === currentStudent.lastname
            )
        )
            return uniqueStudents;
        return [...uniqueStudents, currentStudent];
    }, [] as { lastname: string; firstname: string }[]);

    // Reopen modal when the list of students changes
    useEffect(() => {
        setShowModal(true);   
        console.log("students", students);
           
    }, [students]);

    useEffect(() => {
        if(showModal === false){
            onModalClose()
        }
    }, [showModal])

    return (
        <Modal
            title={"Démarrer les parcours"}
            showDialog={showModal}
            setShowDialog={setShowModal}
        >
            <div id="init-path-modal">
                <Accordion collapsible>
                    <AccordionItem>
                        <AccordionButton>
                            {uniqueStudents.length !== 1 ? (
                                <>
                                    <span>{uniqueStudents.length} élèves</span> sont en attente pour démarrer le module <strong>{getModuleById(students[0].moduleId, data).title.short}. { getModuleById(students[0].moduleId, data).title.long }</strong>
                                </>
                            ) : (
                                <>
                                    <span>1 élève</span> est en
                                    attente pour démarrer le module <strong>{getModuleById(students[0].moduleId, data).title.short}. {getModuleById(students[0].moduleId, data).title.long }</strong>
                                </>
                            )}
                        </AccordionButton>
                        <AccordionPanel>
                            <ul>
                                {uniqueStudents.map((student, i) => (
                                    <li key={"student-" + i}>
                                        {student.firstname} {student.lastname}
                                    </li>
                                ))}
                            </ul>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <div className="icon-container">
                    <div className="icon">
                        <span className="student-number">{uniqueStudents.length}</span>
                        <span className="material-icons">person</span>
                    </div>
                    
                </div>
                <p>
                    De nouveaux élèves ont effectué leur test
                    diagnostique.Veuillez démarrer les parcours pour leur
                    permettre d’accéder à leur parcours personnalisé.
                </p>
                <div className="buttons-container">
                    <Button
                        style="secondary"
                        label={"Reporter"}
                        onClick={onModalClose}
                        centeredLabel={true}
                    />
                    <Button
                        style="primary"
                        label={"Démarrer les parcours"}
                        onClick={onStartPath}
                        centeredLabel={true}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PathStartModal;
