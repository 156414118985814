import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import { Title } from "../../interfaces/Config";
import { dashboardStore } from "../../contexts/DashboardContext";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";
import ClassList from "./ClassList/ClassList";
import ClassDetails from "./ClassDetails/ClassDetails";
import StudentDetails from "../DashBoard/StudentDetails/StudentDetails"
import ProgressionOverview from "./ProgressionOverview/ProgressionOverview";
import { getData } from "../../utils/request-data";
import Loader from "../../components/Loader/Loader";
import DemoBanner from "../../components/DemoBanner/DemoBanner";
import Search from "./Search/Search";
import * as localStorage from '../../utils/localStorage';
import "./Dashboard.scss";
import Clustering from "../Clustering/Clustering";
import { ClusterError, Dashboard, ModuleCluster } from "../../interfaces/Dashboard";

interface IClustering {
  [classId: string]: {
    [moduleId: string]: ModuleCluster;
  };
}

function DashboardPage({ title }: Title) {
  let { path } = useRouteMatch();

  const { dashboard, setDashboard } = useContext(dashboardStore);
  const { config } = useContext(configStore);
  const { session } = useContext(sessionStore);

  const [loader, setLoader] = useState<boolean>(true)

  const getTeacherData = async () => {
    const version = localStorage.getItem<string>(localStorage.Key.VERSION);
    if (!version) throw new Error() // [TODO] Error handling
    let authHeaders = new Headers({ 
      "x-evb-origin":  window.location.origin,
      "Authorization": 'Bearer ' + localStorage.getItem<string>(localStorage.Key.TOKEN),
      "version": version
    })
    console.log("DASHBOARD: GETTING DATA...");
    const dashboardData = await getData<Dashboard>(config.msConfigs?.endpoints.analytics + "/teacher/" + session.userId + "/classrooms_analytics/", authHeaders)

    //Induce an error for getStudentName
    /* let a = dashboardData.clustering! as IClustering;
    a["616fc68909558a0a324fecf2"]["5e270b85cba2ee3f83b56776"].infosClustering.clusters[0]["cluster0"].eleves.push("616fc6ba09558a0a324fecf9") */

    if( dashboardData.classrooms ){
      console.log("DASHBOARD: DATA FETCHED", dashboardData);
      setDashboard(dashboardData)
      console.log("DASHBOARD: DATA SET");
      setLoader(false)
    }else{
      //TODO: error handling
      console.log("DASHBOARD: DATA ERROR");
    }
  }

  useEffect(() => {
    console.log("dashboard Page", dashboard);
    getTeacherData()
  }, [])

  return (
    <div className="dashboard">
      <Helmet>
        {" "}
        <title>{title}</title>{" "}
      </Helmet>

      <Switch>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/:classroomId`}>
          <>
            {config.features.demoMode?.displayIn.classDetails ? <DemoBanner position={'right'} message={config.features.demoMode.message.global } /> : null}
            { loader ? <Loader /> : 
              <>
                <Search />
                <ClassDetails /> 
              </>
            }
          </>
        </Route>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/:classroomId/:moduleId`} >
          <>
            {config.features.demoMode?.displayIn.progressionOverview ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            { loader ? <Loader /> : 
              <>
                <Search />
                <ProgressionOverview classrooms={dashboard.classrooms} /> 
              </>
            }
          </>
        </Route>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.student}/:classroomId/:studentId`} >
          <>
            {config.features.demoMode?.displayIn.studentDetails ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            { loader ? <Loader /> : 
              <>
                <Search />
                <StudentDetails /> 
              </>
            }
          </>
        </Route>

        { dashboard.clustering && <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/:classroomId/:moduleId/${config.i18n.dashboard?.paths.groups}`} >
          <>
            {config.features.demoMode?.displayIn.clustering ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            { loader ? <Loader /> : 
              <>
                <Search />
                <Clustering classrooms={dashboard.classrooms} clustering={dashboard.clustering} /> 
              </>
            }
            
          </>
        </Route> }

        {/* Default route (no path) */}

        <Route>
          <>
            { config.features.demoMode?.displayIn.classList ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            { loader ? <Loader /> : 
              <>
                <Search />
                <ClassList classrooms={dashboard.classrooms} />
              </>
            }
          </>
        </Route>
      </Switch>
    </div>
  );
}

export default DashboardPage;
