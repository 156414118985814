import { Properties } from "csstype";
import { configStore } from "../../../../contexts/ConfigContext";
import * as localStorage from "../../../../utils/localStorage"
import React, { useEffect, useState, useContext } from "react";
import TriangleTooltip from "../../../../components/TriangleTooltip/TriangleTooltip";
import Dialog from "@reach/dialog";

import "./QuickStartCode.scss";
const tooltipStyle: Properties<string | number, string & {}> = {
    maxWidth: "480px",
    backgroundColor: "rgba(55, 55, 55, 0.8)",
    color: "white",
    whiteSpace: "normal",
    border: "none",
    borderRadius: "4px",
    padding: "20px",
    paddingLeft: "28px",
    boxSizing: "border-box",
    fontSize: 14 / 16 + "rem",
};



const startTimer = (
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>,
    setQuickStart: React.Dispatch<React.SetStateAction<{code: string; timer: NodeJS.Timeout;} | undefined>>,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    config: any,
    classroomId: string) => {
    
    const headers = new Headers({
        version: localStorage.getItem<string>(localStorage.Key.VERSION)!,
        authorization: `Bearer ${localStorage.getItem(
            localStorage.Key.TOKEN
        )!}`,
    });
    fetch(
        `${config.msConfigs!.endpoints
            .misc!}/session_start_code/${
            classroomId
        }`,
        { method: "get", headers }
    ).then(
        async function onfulfilled(res) {
            const resp = await res.json()
            setQuickStart(() => {
                return {
                    code: resp.code,
                    timer: setInterval(() => {
                        setTimeLeft(resp.expiresAt - (Date.now() / 1000));
                    }, 1000),
                };
            });
            setModalOpen(true)
        },
        function onrejected(res) {
            console.log("Rejected, res", res)
        }
    )
}

const CODE_LIFESPAN = 300;

const QuickStartCode: React.FC<{classroomId: string}> = ({classroomId}) => {
    const [quickStart, setQuickStart] =
        useState<{ code: string; timer: NodeJS.Timeout }>();
    const [timeLeft, setTimeLeft] = useState<number>(CODE_LIFESPAN);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { config } = useContext(configStore);

    // End quickstart when timeleft expires
    useEffect(() => {
        if (!quickStart || timeLeft > 0) return;
        clearInterval(quickStart.timer);
        if (!modalOpen) setQuickStart(undefined);
    }, [timeLeft, quickStart, modalOpen]);

    // Cleaup intervals on component destruction
    useEffect(() => {
        return () => {
            if (quickStart) clearInterval(quickStart.timer);
        };
    }, []);

    return (
        <div className="quick-start">
            {quickStart ? (
                modalOpen ? (
                    <QuickStartModal
                        onDismiss={() => {
                            setModalOpen(false);
                        }}
                        code={quickStart.code}
                        timeLeft={timeLeft}
                        onRestart={() => {
                            startTimer(setTimeLeft, setQuickStart, setModalOpen, config, classroomId);
                        }}
                    />
                ) : (
                    <QuickStartMinified
                        code={quickStart.code}
                        timeLeft={timeLeft}
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    />
                )
            ) : (
                <QuickStartStarter
                    onClick={() => {
                        startTimer(setTimeLeft, setQuickStart, setModalOpen, config, classroomId);
                    }}
                />
            )}
        </div>
    );
};

const QuickStartModal: React.FC<{
    onDismiss: () => void;
    onRestart: () => void;
    timeLeft: number;
    code: string;
}> = ({ onDismiss, timeLeft, code, onRestart }) => {
    return (
        <Dialog onDismiss={onDismiss} className="quick-start__modal" aria-label="Quick Start Modal">
            <h3 className="modal__title">
                <span className="material-icons modal__title--accent">
                    login
                </span>
                Démarrage de session
            </h3>
            <div className="modal__content">
                <p>
                    Communiquez ce code de démarrage au élèves afin qu’ils
                    puissent entrer dans l’application :
                </p>
                <p className="content__code">
                    {code
                        .split("")
                        .map((char, index) =>
                            char >= "0" && char <= "9" ? (
                                <span className="content__code--number" key={"code-content-" + index}>
                                    {char}
                                </span>
                            ) : (
                                char
                            )
                        )}
                </p>
                <p className="content__time-left">
                    <span className="material-icons">history</span>
                    Ce code expire dans{" "}
                    {getHumanReadableTimeLeft(Math.max(0, timeLeft))}
                </p>
                {timeLeft <= 0 && (
                    <div className="content__expired-code">
                        <div className="expired-code__overlay"></div>
                        <div className="expired-code__content">
                            <p>
                                <span className="material-icons expired-code--cross">
                                    history
                                </span>{" "}
                                Code expiré
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className="modal__actions">
                {timeLeft <= 0 && (
                    <button onClick={onRestart} className="actions__restart">
                        <span className="material-icons">history</span>Générer
                        un nouveau code
                    </button>
                )}
                <button onClick={onDismiss} className="actions__close-button">
                    Terminer
                </button>
            </div>
        </Dialog>
    );
};

const QuickStartMinified: React.FC<{
    code: string;
    timeLeft: number;
    onClick: () => void;
}> = ({ code, timeLeft, onClick }) => {
    return (
        <button className="quick-start__button" onClick={onClick}>
            <span>Code de démarrage: {code}</span>
            <p className="quick-start__button--small-text --set-width">
                <span className="material-icons">history</span>
                {getHumanReadableTimeLeft(timeLeft)} restant
                {timeLeft > 1 ? "es" : ""}
            </p>
        </button>
    );
};

const QuickStartStarter: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <>
            <button className="quick-start__button" onClick={onClick}>
                <span className="material-icons">login</span>
                <span>Démarrage de sessions</span>
            </button>
            <TriangleTooltip
                label="Obtenez un code de démarrage à communiquer aux élèves afin qu’ils puissent entrer dans l’application"
                style={tooltipStyle}
                pointing="left"
            >
                <span className="material-icons quick-start__tooltip">
                    help_outline
                </span>
            </TriangleTooltip>
        </>
    );
};

const getHumanReadableTimeLeft = (timeLeft: number): string => {
    let readableTimeLeft = "";
    if (timeLeft > 60) readableTimeLeft += Math.floor(timeLeft / 60) + "min";
    readableTimeLeft += " " + Math.ceil(timeLeft % 60) + "s";
    return readableTimeLeft;
};

export default QuickStartCode;