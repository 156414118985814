import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'moment-duration-format';
import Loader from "../../../../components/Loader/Loader";


import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";

import { ModuleDashboard } from "../../../../interfaces/Dashboard";
import { getModuleById } from "../../../../utils/dataRetrieval";


//Utils
import { colors } from "../utils";

// const graphData = {
//     labels: ["L", "M", "M", "G", "V", "S", "D"],
//     datasets: [{
//       label: 'Modulo 1',
//       data: [1, 2, 3, 4, 5, 6, 7],
//       backgroundColor: colors.module1,
//     },
//     {
//       label: 'Modulo 2',
//       data: [5, 3, 2, 1, 5, 6, 8],
//       backgroundColor: colors.module2,
//     },
//     {
//       label: 'Modulo 3',
//       data: [3, 4, 6, 7, 1, 0, 3],
//       backgroundColor: colors.module3,
//     }]
//   }

  const options = {
      scales: {
        x: {
          stacked: true,
          grid: { display: false },
          ticks: {
                // Only show the first letter of the day in the legend
                callback: function (value: any): string {
                    /* @ts-ignore: otherwise TS doesn't recognize "this" as valid */
                    return this.getLabelForValue(value).slice(0, 1);
                },
            },
        },
        y: {
          display: false,
          stacked: true,
          grid: { display: false }
        }
      },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context:any) {

            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== 0) {
              label += moment.duration(context.parsed.y, 'seconds').format('mm [min] ss [sec]');
              
            } else {
              label += '00 min 00 sec'
            }
            return label;
          }
        }
      },
      legend: {
        position: 'bottom' as "bottom"
      }
    }
  }

interface TimeSpentProps {
  studentModules: ModuleDashboard[] | undefined;
  studentId: string;
}

interface ModuleDataObj {
  label: string;
  data: number[];
  backgroundColor?: string;
}

interface GraphDataTypes {
  labels: string[];
  datasets: ModuleDataObj[];
}

export default function TimeSpentChart({ studentModules, studentId }: TimeSpentProps) {
  
  const { config } = useContext(configStore);
  const { data } = useContext(dataStore);

  const [currentDatasets, setCurrentDatasets] = useState();
  const [graphData, setGraphData] = useState <GraphDataTypes>();
  const [currentStartingDayOfWeek, setCurrentStartingDayOfWeek] = useState<string>();
  const [timePassedOnModules, setTimePassedOnModules] = useState<number>(0);

  useEffect(() => {
    const firstDayOfWeek = moment().startOf("isoWeek").format("YYYY-M-D"); 
    setCurrentStartingDayOfWeek(firstDayOfWeek);

  }, []);

  useEffect(() => {
    console.log('useEffect', moment("2021-7-11").format('YYYY-M-D'));
    
    const datasets: ModuleDataObj[] = [];
    let minutesPassedOnModules = 0;
    studentModules?.forEach((module, i) => {
      const moduleData = { label: module.id, data: Array(), backgroundColor: `${colors.modules[i] || '#fff'}`};
      moduleData.label = getModuleById(module.id, data).title.short!;
      for(let i=0; i<=6; i++) {
        let timeSpentKey = moment(currentStartingDayOfWeek).add(i, "days").format("YYYY-MM-DD");
        const timeSpentValue = module.students[studentId].timeSpent[timeSpentKey] || 0;
        minutesPassedOnModules = minutesPassedOnModules + timeSpentValue;
        moduleData.data.push(timeSpentValue);
      }
      datasets.push(moduleData);
    });
    setGraphData({ labels: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"], datasets})
    setTimePassedOnModules(minutesPassedOnModules);
  }, [studentModules, currentStartingDayOfWeek, data, studentId]);

  const handleChangeCurrentWeek = (arg: string) => {
    if (arg === 'previous') {
      const previousMonday = moment(currentStartingDayOfWeek).subtract(7, 'days').isoWeekday('Monday').format("YYYY-M-DD");
      setCurrentStartingDayOfWeek(previousMonday);
      
    } else if (arg === 'next') {
      const currentMonday = moment().isoWeekday('Monday').format("YYYY-M-DD");

      if (currentMonday === currentStartingDayOfWeek) {
        return
      } else {
        const nextMonday = moment(currentStartingDayOfWeek).add(7, 'days').isoWeekday('Monday').format("YYYY-M-DD");
        setCurrentStartingDayOfWeek(nextMonday);
      }
    }
  }

  if (!graphData) return <Loader />

  return (
    <ChartCard>
      <ChartDateContainer>
        <ChartDateButton onClick={() => handleChangeCurrentWeek("previous")}>
            <span className="material-icons">arrow_back</span>
        </ChartDateButton>
        <div>{moment(currentStartingDayOfWeek).isoWeekday('Monday').format('DD/MM/YYYY')} - {moment(currentStartingDayOfWeek).isoWeekday('Sunday').format('DD/MM/YYYY')}</div>
        <ChartDateButton onClick={() => handleChangeCurrentWeek("next")}>
            <span className="material-icons">arrow_forward</span>
        </ChartDateButton>
      </ChartDateContainer>
      <WeekTotal><HoursSpent>{moment("2021-01-01").startOf('day').seconds(timePassedOnModules).format('HH:mm')}</HoursSpent> {config.i18n.dashboard?.studentDetails.thisWeek}</WeekTotal>
      {/* <WeekTotal><HoursSpent>{timePassedOnModules}</HoursSpent> {config.i18n.dashboard?.studentDetails.thisWeek}</WeekTotal> */}
      <ChartWrapper>
        <Bar /* type="bar" */ data={graphData} options={options} />
      </ChartWrapper>
    </ChartCard>
  )
}


const ChartCard = styled.div`
  background: ${colors.lightGrey};
  border-radius: 8px;
  padding: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 52px);
  display:flex;
  flex-direction: column;
`

const ChartDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChartDateButton = styled.div`
  background-color: #ccc;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 8px;
  margin: 16px 8px;
  cursor: pointer;
  user-select: none;
`;

const WeekTotal = styled.h3`
  padding: 8px 16px;
  font-size: 1.2em;
`;

const HoursSpent = styled.span`
  font-size: 1.8em;
`

const ChartWrapper = styled.div`
  width: 400px;
  height:calc(100% - 48px);
  padding: 24px;
  margin:0 auto;
  display:flex;
  align-items: center;
`;