import { useContext } from "react";
import * as Sentry from "@sentry/react";
import { Statement } from "@xapi/xapi";
import { HierarchyIds } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";
import * as localStorage from "../utils/localStorage";
import { getCurrentAgent, makeStatement } from "../utils/tracking";

const useStatements = (): {
    sendResult: (
        historyItem: HierarchyIds & {
            score: number;
            success: boolean;
            isInitialTest: boolean;
            answer?: any;
            duration: number;
        }
    ) => Promise<void>;
    getResultStatements: () => Promise<Statement[]>;
} => {
    const {
        config: { msConfigs, declinaison },
    } = useContext(configStore);
    const {
        session: {
            evidencebId,
            sessionId,
            flags: { useHistoryFrom },
        },
    } = useContext(sessionStore);

    return {
        sendResult: async (historyItem) => {
            const statement = makeStatement(
                historyItem,
                sessionId,
                evidencebId,
                declinaison
            );

            if (useHistoryFrom === "LRS") {
                try {
                    // TODO : handle response (what to do in case of error?)
                    await fetch(msConfigs!.endpoints.statements!, {
                        method: "post",
                        headers: new Headers({
                            Authorization:
                                "Bearer " +
                                localStorage.getItem<string>(
                                    localStorage.Key.TOKEN
                                ),
                            "x-evidenceb-app": "athena",
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(statement),
                    });
                } catch (err) {
                    // TODO: Error handling
                    Sentry.captureException("Cannot POST statement");
                }
            } else {
                const statements =
                    localStorage.getItem<Statement[]>(
                        localStorage.Key.LOCAL_HISTORY
                    ) ?? [];
                localStorage.setItem(
                    localStorage.Key.LOCAL_HISTORY,
                    JSON.stringify([...statements, statement])
                );
            }
        },

        getResultStatements: async () => {
            if (useHistoryFrom === "LRS") {
                const agent = getCurrentAgent(declinaison, evidencebId);
                try {
                    const res = await fetch(
                        msConfigs!.endpoints.statements! +
                            "?agent=" +
                            encodeURIComponent(JSON.stringify(agent)),
                        {
                            method: "get",
                            headers: new Headers({
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem<string>(
                                        localStorage.Key.TOKEN
                                    ),
                                "x-evidenceb-app": "athena",
                            }),
                        }
                    );
                    const body = await res.json();
                    return body.statements ?? ([] as Statement[]);
                } catch (err) {
                    // TODO: Error handling
                    Sentry.captureException("Cannot GET statements");
                    return [];
                }
            } else {
                return new Promise((resolve) => {
                    const statements = localStorage.getItem<Statement[]>(
                        localStorage.Key.LOCAL_HISTORY
                    );
                    resolve(statements ?? []);
                });
            }
        },
    };
};

export default useStatements;
