import React from "react";
import { parse } from "../../utils/parse-text"
import { ContentWithTitle } from "@evidenceb/gameplay-interfaces";

import "./WizardFeedbackDisplay.scss";

const WizardFeedbackDisplay: React.FC<
    ContentWithTitle & { isCorrect: boolean }
> = ({ isCorrect, title, text, image, imageAlt }) => {
    return (
        <div className="wizard-feedback-display">
            {typeof isCorrect !== "undefined" ? (
                <div
                    className={`feedback_correctness ${
                        isCorrect ? "--correct" : " --incorrect"
                    }`}
                >
                    <div className="icon-container">
                        <span className="material-icons">
                            {isCorrect
                                ? "sentiment_very_satisfied"
                                : "sentiment_very_dissatisfied"}
                        </span>
                    </div>
                </div>
            ) : null}

            {title ? (
                <p>
                    <span className={isCorrect ? "correct" : "incorrect"}>
                        {parse(title)}
                    </span>
                </p>
            ) : null}
            <div className="feedback__content">
                {text ? (
                    <div className="feedback_explanation">
                        <div className="content">{parse(text.$html)}</div>
                    </div>
                ) : null}

                {image ? <img src={image} alt={imageAlt} /> : null}
            </div>
        </div>
    );
};

export default WizardFeedbackDisplay;
