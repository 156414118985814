import React from "react";
import styled from "styled-components/macro";

import { colors } from "../utils";

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.lightGrey};
  width: 40px;
  height: 40px;
  margin: 0 8px;
  font-size: 3.2em;
  color: ${colors.redIcon};
  padding: 8px;
  border-radius: 50%;
`

export default function StudentIcon() {
  return (
    <Icon>
      <span>!</span>
    </Icon>
  )
}