import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
    ActivityShell,
    Exercise,
    ExerciseResult,
    PlaylistExecutionStage,
} from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import Loader from "../../components/Loader/Loader";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import WizardShell from "../../components/WizardShell/WizardShell";
import { getExerciseById, getUrl } from "../../utils/dataRetrieval";
import ChatbotShell from "../../components/ChatbotShell/ChatbotShell";
import { sessionStore } from "../../contexts/SessionContext";

import "./SingleExercisePlayer.scss";

const SingleExercisePlayer: React.FC = () => {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const { session: { userType } } = useContext(sessionStore);
    const { shell, exerciseId } = useParams<{
        shell: ActivityShell;
        exerciseId: string;
    }>();
    const [exercise, setExercise] = useState<Exercise<any, any> | undefined>(
        undefined
    );
    const [
        playlistExecutionStage,
        setPlaylistExecutionStage,
    ] = useState<PlaylistExecutionStage>(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    const [exerciseResult, setExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentTry, setCurrentTry] = useState<number>(1);

    const Shell = shell === ActivityShell.Chatbot ? ChatbotShell : WizardShell;

    useEffect(() => {
        try {
            const newExercise = getExerciseById(exerciseId, data, false);
            setExercise(newExercise);
        } catch (err) {
            // TODO: Handle exception. For now single exercise player is only
            // used in development so we don't want this error to be logged
            // everytime in Sentry
        }
    }, [data, exerciseId]);

    if (typeof exercise === "undefined") return <Loader />;

    return (
        <div className="single-player-container player-container">
            <PlayerHeader>
                <div className="header_close">
                    <Link
                        to={`/${ getUrl( config.pages.find( page => page.type === "MODULELIST" )! , userType)}`}
                    >
                        <span className="material-icons">clear</span>
                        <span>{config.i18n.exerciseShell.close}</span>
                    </Link>
                </div>
            </PlayerHeader>

            <main>
                <Shell
                    onGoToNextExercise={() => {
                        if (
                            exerciseResult &&
                            !exerciseResult.correct &&
                            currentTry <
                                (exercise.executionOptions?.numberOfTries ??
                                    exercise.numberOfTries)!
                        ) {
                            setCurrentTry((curr) => curr + 1);
                            setExerciseResult(undefined);
                            setPlaylistExecutionStage(
                                PlaylistExecutionStage.PlayingCurrentExercise
                            );
                        }
                    }}
                    onExerciseResult={(partialExerciseResult) => {
                        setExerciseResult({
                            ...partialExerciseResult,
                            exerciseId: exercise.id,
                            activityId: "toto", // TEMP: Delete when property is made optional
                            try: 1,
                            feedback: exercise.feedback[0][partialExerciseResult.correct ? "correct" : "incorrect"]
                        });
                        setPlaylistExecutionStage(
                            PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                        );
                    }}
                    playlist={{
                        module: data.modules[0], // TODO: Remove when made optional
                        objective: data.objectives[0], // TODO: Idem,
                        activity: data.activities[0], // TODO Idem
                        exercises: [exercise],
                        exerciseResults: exerciseResult ? [exerciseResult] : [],
                        currentExecutionStage: playlistExecutionStage,
                        currentExercise: exercise,
                        currentExerciseResult: exerciseResult,
                        currentTry,
                        comingNext: exerciseResult
                            ? !exerciseResult.correct &&
                              currentTry <
                                  (exercise.executionOptions?.numberOfTries ??
                                      exercise.numberOfTries)!
                                ? "retry"
                                : "endOfPlaylist"
                            : undefined,
                    }}
                />
            </main>
        </div>
    );
};

export default SingleExercisePlayer;
